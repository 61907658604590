<template>
  <AppDialog v-model="showFraudInfo" class="recipient-modal-wrapper">
    <AppDialogHeader class="consent-header">
      <!-- <XeBackButton @click="onFraudGotIt" class="consent-header-back-button" /> -->
    </AppDialogHeader>

    <AppCardImageTitle
      :title="$t('ComponentModifyRecipientModal.TitleFraudDialog').value"
      :src="require('@galileo/assets/images/illustrations/em_xe.svg')"
      alt="Take a moment and stop fraud"
      class="cardImage"
    >
      <p class="infoDialog">{{ $t('ComponentModifyRecipientModal.FraudDialogInfo').value }}</p>
    </AppCardImageTitle>

    <AppDialogFooter>
      <AppButton analytics-name="modify-recipient-fraud-dialog-ok" @click="onFraudGotIt">
        {{ $t('ComponentModifyRecipientModal.ButtonFraudGotIt').value }}
      </AppButton>

      <AppButton
        analytics-name="modify-recipient-fraud-dialog-contact-us"
        theme="text"
        :loading="loadingContactUs"
        @click="onFraudContactUs"
      >
        {{ $t('ComponentModifyRecipientModal.ButtonVisitHelpCenter').value }}
      </AppButton>
    </AppDialogFooter>
  </AppDialog>
</template>

<script>
import { ref } from '@vue/composition-api'

import {
  AppDialogHeader,
  AppDialog,
  AppDialogFooter,
  AppCardImageTitle,
  AppButton,
} from '@oen.web.vue2/ui'

import { useAppStore, useI18nStore } from '@galileo/stores'

export default {
  components: {
    AppDialogHeader,
    AppDialog,
    AppDialogFooter,
    AppCardImageTitle,
    AppButton,
  },
  props: {
    showFraudInfo: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const appStore = useAppStore()

    const loadingContactUs = ref(false)

    const onFraudGotIt = () => {
      emit('onFraudGotIt', false)
    }
    const onFraudContactUs = () => {
      appStore.openHelpDesk({ loadingRef: loadingContactUs })
    }
    return {
      loadingContactUs,
      onFraudGotIt,
      onFraudContactUs,
      $t,
    }
  },
}
</script>
