<template>
  <div class="layout-dashboard-page" :class="classes">
    <h1 v-if="title" class="title">{{ title }}</h1>
    <div v-if="$slots.alert" class="alert">
      <slot name="alert" />
    </div>
    <slot />
  </div>
</template>

<script>
import { onUpdated, reactive, getCurrentInstance } from '@vue/composition-api'
export default {
  name: 'LayoutDashboardPage',
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup(props, { slots }) {
    const classes = reactive({
      'layout-dashboard-page--hasAlert': slots.alert,
    })

    const currentInstance = getCurrentInstance()

    onUpdated(() => {
      //react to changing slots.alert since the slot object is not reactive
      classes['layout-dashboard-page--hasAlert'] = !!currentInstance.slots.alert
    })

    return {
      classes,
    }
  },
}
</script>
<style scoped>
.layout-dashboard-page {
  @apply flex flex-col max-w-5xl mx-auto mb-10;
  @screen sm {
    @apply px-5;
  }

  .title {
    @apply type-h1 mb-8;

    @screen sm {
      @apply mb-10;
    }
  }

  &.layout-dashboard-page--hasAlert {
    .title {
      @apply mb-4;

      @screen sm {
        @apply mb-6;
      }
    }

    .alert {
      @apply mb-6;

      @screen sm {
        @apply mb-10;
      }
    }
  }
}
</style>
