<template>
  <div>
    <InformationAppModal
      :value="value"
      :button-title="$t('PageSendMoneyVerifyIdentity.ButtonContinue').value"
      :is-loading="isLoading"
      should-display-close-button
      @close="closeModal"
      @confirm="proceed"
    >
      <!-- Modal content for Personal accounts -->
      <template v-if="isOwnAccount">
        <h1 class="title">{{ $t('ZarWarningModal.YourIdentityTitle').value }}</h1>
        <!-- We need to verify your identity -->
        <p>
          {{ $t('ZarWarningModal.YourIdentityFirstParagraphTitle').value }}
        </p>
        <!-- <p>
        You need to complete South Africa Reserve Bank reporting requirements before you can send ZAR
      </p> -->
        <p>
          {{ $t('ZarWarningModal.YourIdentitySecondParagraphTitle').value }}
        </p>
        <!-- <p>You'll be contacted by our partner Sikhona Money Transfer to complete this.</p> -->
      </template>

      <!-- Modal content for somone elses account -->
      <template v-else>
        <h1 class="title">{{ $t('ZarWarningModal.SomeoneElseIdentityTitle').value }}</h1>

        <!-- <h1 class="title">Let your recipient know</h1> -->
        <p>
          {{ $t('ZarWarningModal.SomeoneElseIdentityFirstParagraphTitle').value }}
        </p>
        <!-- <p>
        Recipients need to complete South African Reserve Bank reporting requirements before they can receive ZAR from abroad.
      </p> -->
        <p>
          {{ $t('ZarWarningModal.SomeoneElseIdentitySecondParagraphTitle').value }}
        </p>
        <!-- <p>
        Please let your recipient know that they'll be contacted by our partner Silkhona Money Transfer to complete this
      </p> -->
      </template>
    </InformationAppModal>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from '@vue/composition-api'
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'
import { useI18nStore } from '@galileo/stores'

import { useRecipientUpdate } from '@galileo/composables/useRecipientHelpers'
import { DELIVERY_METHODS } from '@galileo/constants/sendMoneyFlow.const'
export default defineComponent({
  components: { InformationAppModal },
  emits: ['close'],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    recipient: {
      type: Object,
      default: () => {},
      // validator: (form) => {
      //   return form.hasOwnProperty('country') && form.hasOwnProperty('currency')
      // },
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const isLoading = ref(false)
    const isOwnAccount = computed(() => {
      if (props.recipient) {
        return props.recipient.isOwnAccount
      }
      return false
    })

    const closeModal = () => {
      emit('close')
    }

    /**
     * we fetch dynamic fields for the recipient
     * we add the field value
     * we send the information back to the server
     *
     * as a callback we stop the loading state
     * and emit the proceed
     *
     */
    const proceed = async () => {
      isLoading.value = true

      const { fetchFormFields, addFormFieldValue, updateRecipient } = useRecipientUpdate(
        props.recipient
      )

      await fetchFormFields()
      addFormFieldValue('HAS_RIA_ALERT_ACCEPTED', 'True')
      if (props.recipient.deliveryMethod !== DELIVERY_METHODS.CASH_PAYOUT) {
        await updateRecipient(null, () => {
          isLoading.value = false
          emit('proceed')
        })
      } else {
        isLoading.value = false
        emit('proceed')
      }
    }

    return { closeModal, $t, proceed, isOwnAccount, isLoading }
  },
})
</script>
