<template>
  <div :class="{ 'with-bottom-border': bottomBorder, 'margin-top': true }">
    <h4 class="detail-title">{{ title }}</h4>
    <div class="section-container">
      <div>
        <slot></slot>
      </div>
      <div v-if="showEditButton" class="self-center">
        <button v-on:click="$emit('edit')" >
          <AppIcon name=" " class="full-width-icon" :data-testid="editRecipientDataTestId">
            <IconEdit />
          </AppIcon>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import { IconEdit } from '@oen.web.vue2/icons'



import { AppIcon, AppButton } from '@oen.web.vue2/ui'

export default defineComponent({
  emits: ['edit'],
  components: { AppIcon, IconEdit, AppButton },
  props: {
    title: {
      type: String,
      default: '',
    },
    bottomBorder: {
      type: Boolean,
      default: false,
    },
    showEditButton: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  setup(props) {
    const editRecipientDataTestId = ref(
      'edit-' + props.title.toLowerCase().replace('\'','').replace(' ','-') + '-icon'
    )

    return {
      editRecipientDataTestId
    }
  },
})
</script>

<style scoped>
.section-container {
  @apply flex flex-row justify-between;
  @apply py-4;
  @apply text-sm text-text-primary leading-5;
}

.full-width-icon {
  width: 24px;
  height: 24px;
  /* 24 px -> 19.5 px for the icon inside */
  @apply text-icon-primary !important;
}

.with-bottom-border {
  border-bottom: 1px solid #f3f4f6;
}
.margin-top {
  @apply mt-6;
}

.detail-title {
  @apply uppercase;
  @apply font-semibold text-gray-dark;
  font-size: 10px;
}
</style>
