<template>
  <div>
    <AppModal v-model="showSelf" title="Recipient’s address" :loading="true">
      <template #header>
        <AppModalHeader>
          <template #default> {{ $t('EditRecipientAddressModal.ModalTitle').value }} </template>
          <template #left>
            <XeBackButton
              v-if="!isMissingInformationFlow"
              :name="$t('PageSendMoneyAdditionalDetails.GoBackTooltipText').value"
              icon="<"
              analytics-name="bank-details-modal-back"
              @click="$emit('back')"
            />
          </template>
          <template #right>
            <XeBackButton
              :name="$t('RecipientDetailsModal.CloseTooltipText').value"
              icon="x"
              analytics-name="bank-details-modal-dismiss"
              @click="$emit('close')"
            />
          </template>
        </AppModalHeader>
      </template>

      <AppSpinnerBig key="1" class="spinner" :loading="loading" />
      <Transition name="fade">
        <DeactivatedRecipientWrapper key="2" :recipient="recipient" v-if="!loading">
          <AppInputCountry
            :empty-filter-message="$t('AddressSearch.noResult').value"
            :key="validation.addressCountry.$value"
            v-model="form.addressCountry"
            :disabled="recipient.deactivated"
            :label="$t('PageRecipients.CountryOfResidence').value"
            :validation="validation.addressCountry"
            :countries="countryList"
            @input="() => onCountryChange(onCountryChangeSpecific)"
          />
          <XeAddressSearch
            v-if="!showEnterManualAddress"
            :key="form.addressLine1"
            :dropdown-label="xeAddressSearchLabel"
            :search-promise-lazy="searchPromiseLazy"
            :get-address-details-promise-lazy="getAddressDetailsPromiseLazy"
            :display-value="form.addressLine1"
            :show-manual-selection="false"
            class="address-search"
            should-output-empty-string
            @select="onSelectAddress"
            @manual="onManualAddress"
          />
          <p
            v-if="
              formValidation.addressLine1 &&
              formValidation.addressLine1.pobox &&
              formValidation.addressLine1.pobox.$invalid
            "
            class="address-search-pobox-error"
          >
            {{ formValidation.addressLine1.pobox.$message }}
          </p>
          <p
            v-else-if="
              formValidation.addressLine1 &&
              formValidation.addressLine1.regex &&
              formValidation.addressLine1.regex.$invalid
            "
            class="address-search-pobox-error"
          >
            {{ formValidation.addressLine1.regex.$message }}
          </p>

          <AppDynamicForm :key="formFieldsToDisplay.length" :fields="formFieldsToDisplay" />
        </DeactivatedRecipientWrapper>
      </Transition>

      <template #footer>
        <AppModalFooter>
          <AppButton
            analytics-name="address-details-modal-update"
            :loading="isLoadingState"
            :disabled="
              (validation && validation.$anyInvalid) ||
              formValidation.$anyInvalid ||
              isLoadingState ||
              recipient.deactivated
            "
            @click="update"
          >
            {{ $t('PageRecipients.SaveButton').value }}
          </AppButton>
        </AppModalFooter>
      </template>
    </AppModal>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, ref, toRef, reactive } from '@vue/composition-api'
import { useValidation, usePromiseLazy } from 'vue-composable'

import { required } from '@vuelidate/validators'

import { getAllISOByCurrency } from '@galileo/composables/useIsoCountryCurrencyLibrary'
import XeAddressSearch from '@galileo/components/XeAddressSearch/XeAddressSearch'
import UserPersonalInformation from '@galileo/models/UserProfile/UserPersonalInformation'
import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'
import ZarWarningModal from '@galileo/components/Views/Recipient/ZARWarningModal/ZarWarningModal'
import DeactivatedRecipientWrapper from '@galileo/components/Views/Recipient/DeactivatedRecipientWarning/DeactivatedRecipientWrapper'
import { useRecipientUpdate } from '@galileo/composables/useRecipientHelpers/index.js'
import XeInputCurrency from '@galileo/components/XeInputCurrency/XeInputCurrency'
import useRecipientAddressFiller from '@galileo/composables/useAddress/useRecipientAddressFiller'

import {
  useI18nStore,
  useResourcesStore,
  useCountriesStore,
  useRecipientsStore,
} from '@galileo/stores'

import {
  useVModel,
  AppModal,
  AppModalHeader,
  AppModalFooter,
  AppButton,
  AppInputCountry,
  AppDynamicForm,
  AppInputText,
  AppInputDropdown,
  AppSpinnerBig,
} from '@oen.web.vue2/ui'
import getValueRequiredValidation from '@galileo/utilities/validations.utility'

export default defineComponent({
  components: {
    AppModal,
    AppModalHeader,
    AppModalFooter,
    AppButton,
    XeBackButton,
    AppInputCountry,
    XeInputCurrency,
    AppDynamicForm,
    XeAddressSearch,
    UserPersonalInformation,
    AppInputText,
    ZarWarningModal,
    DeactivatedRecipientWrapper,
    AppInputDropdown,
    AppSpinnerBig,
  },
  props: {
    recipient: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
    isMissingInformationFlow: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { model } = useVModel(props, emit)
    const showSelf = ref(model.value)
    const { $t } = useI18nStore()
    const resourcesStore = useResourcesStore()
    const countriesStore = useCountriesStore()
    const recipientsStore = useRecipientsStore()
    const xeAddressSearchLabel = ref($t('PageRecipients.AddressLine1').value)
    // const { setupIndustryType, selectedIndustryType } = useIndustryType()
    const {
      fetchFormFields,
      isLoadingState,
      setFormFieldValue,
      formValidation,
      formFields,
      updateRecipient,
      additionalFields,
      selectFormFields,
      addFormFieldValue,
      recipientFormAddressCountry,
    } = useRecipientUpdate(props.recipient, props.isMissingInformationFlow)

    const { onSelectAddress, onCountryChange } = useRecipientAddressFiller(
      setFormFieldValue,
      (addrLine1) => {
        form.addressLine1 = null
        //set a little timeout as if the address select the same address it will still be displayed
        setTimeout(() => (form.addressLine1 = addrLine1), 10)
      }
    )

    const countryList = ref([])
    const formFieldsToDisplay = ref([])
    let showEnterManualAddress = ref(false)
    const form = reactive({
      addressLine1: '',
      country: '',
      addressCountry: '',
    })

    const phoneCountries = resourcesStore.getPhoneCountries

    const validation = ref(null)

    const loading = ref(true)
    const valueRequired = getValueRequiredValidation()
    // ---------------------- Fetch ---------------
    onBeforeMount(async () => {
      countryList.value = countriesStore.getCountries
      form.country = props.recipient.country
      form.addressCountry = props.recipient.addressCountry

      recipientFormAddressCountry.value = form.addressCountry

      form.addressLine1 = props.recipient.formattedAddress.street

      await fetchFormFields()
      selectFormFields('addressFields')
      hideAddressLine1Input()
      const addressLine1Field = formFields.value.find((field) => field.id === 'ADDRESS_LINE1')

      let hasRecipientForm = formFieldsToDisplay.value.find((field) => field.id === 'RECIPIENT_PHONE')
      if(hasRecipientForm){
        const recipientDefaultPhonePrefix = phoneCountries.find((country) => {
          return country.nameAbbr === form.country
        })

        hasRecipientForm.value = hasRecipientForm.value ?? recipientDefaultPhonePrefix?.value.replace('+', '')
      }

      xeAddressSearchLabel.value = addressLine1Field?.label

      validation.value = useValidation({
        addressCountry: {
          $value: toRef(form, 'addressCountry'),
          valueRequired,
        },
      })

      loading.value = false
    })

    const onCountryChangeSpecific = async () => {
      recipientFormAddressCountry.value = form.addressCountry
      await fetchFormFields()
      formFieldsToDisplay.value = formFields.value
      hideAddressLine1Input()
      form.addressLine1 = null
    }

    const hideAddressLine1Input = () => {
      formFieldsToDisplay.value = formFields.value.filter((el) => el.id !== 'ADDRESS_LINE1')
    }

    //---------------------- Triggers
    const onCurrencyChange = async () => {
      props.recipient.country = null
      countryList.value = await recipientsStore.getCountriesForCurrency(props.recipient.currency)
      const matching = getAllISOByCurrency(props.recipient.currency)

      if (matching && matching.length > 0 && matching.length < 5) {
        props.recipient.country = matching[0]
        // onCountryChange()
      }
    }

    const onManualAddress = () => {
      showEnterManualAddress.value = true
      formFieldsToDisplay.value = formFields.value
    }

    //-------------- XE address search
    const searchPromiseLazy = reactive(
      usePromiseLazy(async ({ query, selectedId = null }) => {
        setFormFieldValue('ADDRESS_LINE1', query)
        if (query) {
          let result = await resourcesStore.getAddressSearchResult({
            searchTerm: query,
            searchContext: selectedId,
            country: form.addressCountry || form.country,
          })
          return result
        }
        return null
      })
    )

    //TODO move into composable?
    const getAddressDetailsPromiseLazy = reactive(
      usePromiseLazy((selectedId) => {
        let result = resourcesStore.getAddressDetails(selectedId)
        return result
      })
    )

    //-------------------- Finishing Update --------------

    const update = async () => {
      isLoadingState.value = true

      if (props.recipient?.industryTypeId) {
        addFormFieldValue('INDUSTRY_TYPE', props.recipient.industryTypeId)
      }

      setFormFieldValue('ADDRESS_COUNTRY_CODE', form.addressCountry)
      await updateRecipient(null, onUpdateSucceed)
    }

    const onUpdateSucceed = () => {
      model.value = false
      showSelf.value = false
      emit('success')
    }

    return {
      xeAddressSearchLabel,
      model,
      update,
      countryList,
      isLoadingState,
      formFields,
      formValidation,
      onCurrencyChange,
      onCountryChange,
      onCountryChangeSpecific,
      searchPromiseLazy,
      getAddressDetailsPromiseLazy,
      form,
      $t,
      onSelectAddress,
      onManualAddress,
      showSelf,
      showEnterManualAddress,
      validation,
      additionalFields,
      loading,
      formFieldsToDisplay,
    }
  },
})
</script>

<style scoped>
.card-footer > .button {
  width: 100%;
}
::v-deep .input-label {
  color: black !important;
  @apply text-base;
}

::v-deep .loading-spinner-screen .loading-spinner-container {
  position: relative;
}
.hasError {
  border: 1px red solid;
}
.address-search {
  ::v-deep .p-dropdown {
    @apply border-gray-light;
  }
}
.address-search-pobox-error {
  @apply text-red-text text-xs font-medium -mt-6 mb-2;
}
::v-deep .input-wrapper {
  input {
    @apply text-base;
  }
}
::v-deep .country-name-after-icon {
  @apply text-base;
}

::v-deep .p-dropdown-items-wrapper {
  width: 100%;
}
</style>
