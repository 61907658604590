<template>
  <div class="recipients-empty">
    <AppCard sheet :class="isSendMoney ? 'recipients-empty-send-money' : 'no-recipients'">
      <template #header>
        <XeStepperCardHeader v-if="isSendMoney" analytics-name="xe-send-money-recipients-back" />
      </template>

      <AppCardImageTitle
        class="card-image-title"
        :title="$t('ComponentNoRecipients.Title').value"
        :src="
          isSendMoney
            ? require('@galileo/assets/images/illustrations/telescope.svg')
            : require('@galileo/assets/images/illustrations/telescope.svg')
        "
        alt="telescope"
      >
        {{ $t('ComponentNoRecipients.SendMoneyDescription').value }}
      </AppCardImageTitle>

      <ButtonGroup>
        <AppButton
          class="xe-add-button"
          theme="secondary"
          analytics-name="no-recipients-add"
          @click="$emit('addRecipient')"
        >
          <template #leftIcon>
            <AppIcon name=" ">
              <IconAdd />
            </AppIcon>
          </template>
          {{ $t('ComponentNoRecipients.ButtonAdd').value }}
        </AppButton>

        <!-- <AppButton
        v-if="isSendMoney"
        theme="text-secondary"
        analytics-name="no-recipients-back"
        @click="$emit('back')"
        >{{ $t('ComponentNoRecipients.ButtonBack').value }}</AppButton
      > -->
      </ButtonGroup>
    </AppCard>
  </div>
</template>

<script>
import { useI18nStore } from '@galileo/stores'
import ButtonGroup from '@galileo/components/ButtonGroup/ButtonGroup'
import { IconAdd } from '@oen.web.vue2/icons'
import XeStepperCardHeader from '@galileo/components/XeStepperCardHeader/XeStepperCardHeader'

import { AppButton, AppCard, AppCardImageTitle, AppIcon } from '@oen.web.vue2/ui'

export default {
  name: 'NoRecipients',
  components: {
    ButtonGroup,
    AppCardImageTitle,
    AppCard,
    AppButton,
    IconAdd,
    AppIcon,
    XeStepperCardHeader,
  },
  props: {
    isSendMoney: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['addRecipient', 'back'],
  setup() {
    const { $t } = useI18nStore()

    return {
      $t,
    }
  },
}
</script>

<style scoped>
.recipients-empty {
  .recipients-empty-send-money {
    @apply border-blue-lighter border-solid border;
    ::v-deep .card-content {
      @apply py-4;
    }
    ::v-deep .card-content-block {
      @apply mb-8 mt-4;
    }
  }
}
.card-image-title {
  ::v-deep .card-image-title-image {
    @apply mb-0;
  }
  ::v-deep .card-image-title-content {
    @apply text-base font-normal text-gray-text;
  }
}
</style>
