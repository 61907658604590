<template>
  <AppList>
    <!-- Add Recipient -->

    <RiaListItem
      v-if="showAddRecipient"
      :title="$t('ComponentRiaRecipients.ButtonAddRecipient').value"
      analytics-name="xe-recipients-add"
      @itemClick="addRecipient"
    />

    <AppSpinnerBig v-if="showLoader" :loading="loading" inline />

    <div v-for="(category, idx) in recipientsByCategory" :key="idx">
      <h2 class="type-overline text-tertiary-text recipients-list-title">{{ category.title }}</h2>

      <AppList class="xe-category-list recipient-category-list">
        <EllipsisWrapper
          v-for="recipient in category.recipients"
          :key="recipient.id"
          :value="recipient.fullName"
          no-resize
        >
          <AppListItem
            :analytics-name="`xe-recipients-item-${recipient.id}`"
            @itemClick="viewRecipient(recipient)"
          >
            <template #figure>
              <AppFigure class="xe-figure" theme="gray">
                {{ recipient.initials }}
              </AppFigure>
            </template>
            <template #left>
              <EllipsisWrapper :value="recipient.fullName">
                <AppListItemTitle>{{ recipient.fullName }}</AppListItemTitle>
              </EllipsisWrapper>

              <div v-if="!recipient.deactivated">
                <AppListItemCaption v-if="recipient.bankName">
                  {{
                    recipient.bankName +
                    ' ****' +
                    recipient.bankAccountNumber.substring(recipient.bankAccountNumber.length - 4)
                  }}
                </AppListItemCaption>
                <AppListItemCaption>
                  {{ getRecipientCountryName(recipient) + ', ' + recipient.currency }}
                </AppListItemCaption>
              </div>
              <div v-else>
                <AppListItemCaption class="recipient-deactivated-text">
                  {{ $t('RiaRecipients.RecipientDeactivatedText').value }}
                </AppListItemCaption>
              </div>
            </template>
            <template #action>
              <AppIcon name=" " v-if="!showMenu" class="recipient-action-icon">
                <IconChevronRight />
              </AppIcon>
              <AppMenu
                v-if="showMenu"
                append-to="body"
                class="recipient-menu"
                :analytics-name="`xe-recipients-menu-${recipient.id}`"
              >
                <AppMenuItem
                  :label="$t('ComponentRiaRecipients.MenuItemView').value"
                  :analytics-name="`xe-recipients-menu-view-${recipient.id}`"
                  @click="viewRecipient(recipient)"
                />
                <AppMenuItem
                  :label="$t('ComponentRiaRecipients.MenuItemSend').value"
                  :analytics-name="`xe-recipients-menu-send-${recipient.id}`"
                  @click="sendMoneyRecipient(recipient)"
                />
                <AppMenuItem
                  :analytics-name="`xe-recipients-menu-delete-${recipient.id}`"
                  :label="$t('ComponentRiaRecipients.MenuItemDelete').value"
                  @click="deleteRecipient(recipient)"
                />
              </AppMenu>
            </template>
          </AppListItem>
        </EllipsisWrapper>
      </AppList>
    </div>
  </AppList>
</template>

<script>
import { ref, onBeforeMount, computed } from '@vue/composition-api'

import { IconChevronRight } from '@oen.web.vue2/icons'
import RiaListItem from '@galileo/components/RiaListItem/RiaListItem'
import { DeliveryMethod } from '@galileo/models/Transaction/app'
import { useRecipientHelpers } from '@galileo/composables/useRecipientHelpers'

import {
  AppMenu,
  AppMenuItem,
  AppList,
  AppListItem,
  AppSpinnerBig,
  AppIcon,
  AppFigure,
  AppListItemTitle,
  AppListItemCaption,
} from '@oen.web.vue2/ui'

import EllipsisWrapper from '@galileo/components/Views/Recipient/EllipsisWrapper.vue'

import { useRecipientsStore, useI18nStore, useAuthStore, storeToRefs } from '@galileo/stores'

export default {
  name: 'RiaRecipients',
  components: {
    EllipsisWrapper,
    AppListItemCaption,
    AppListItemTitle,
    AppMenu,
    AppMenuItem,
    AppList,
    AppListItem,
    AppIcon,
    AppSpinnerBig,
    AppFigure,
    IconChevronRight,
    RiaListItem,
  },
  emits: [
    'loaded',
    'select',
    'add',
    'viewRecipient',
    'editRecipient',
    'deleteRecipient',
    'sendMoneyRecipient',
  ],
  props: {
    country: {
      type: String,
      required: false,
      default: null,
    },
    showMenu: {
      type: Boolean,
      required: false,
      default: false,
    },
    showLoader: {
      type: Boolean,
      required: false,
      default: true,
    },
    loadRecipients: {
      type: Boolean,
      default: true,
    },
    showAddRecipient: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      recipientType: { All: 0, Cash: 1 },
    }
  },
  methods: {
    getRecipientsListByType(type) {
      let result = { title: '', recipients: [] }
      switch (type) {
        case this.recipientType.All:
          result.recipients = this.recipients
          break
        case this.recipientType.Cash:
          result.title = this.$t('PageRecipients.CashRecipients').value
          result.recipients = this.recipientsCash
          break
      }
      return result
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const recipientsStore = useRecipientsStore()
    const authStore = useAuthStore()

    const loading = ref(false)

    const { isCorporateAccount } = storeToRefs(authStore)

    onBeforeMount(async () => {
      if (props.loadRecipients) {
        loading.value = true
        await recipientsStore.getRecipients()
        loading.value = false
      }
      emit('loaded', recipientsByCategory)
    })

    const recipientsByCategory = computed(() => {
      const categories = []
      const recipients = recipientsStore.recipients
      if (recipients && recipients.length > 0) {
        const recipientsOwn = []
        const recipientsBusiness = []
        const recipientsOther = []
        const recipientsCash = []
        const recipientsMobileWallet = []
        recipients.forEach((recipient) => {
          if (recipient && !recipient.rawData.isGlobal) {
            if (recipient.deliveryMethod === DeliveryMethod.CashPickup.enumName) {
              recipientsCash.push(recipient)
            } else if (recipient.isOwnAccount) {
              recipientsOwn.push(recipient)
            } else if (recipient.isBusinessAccount) {
              recipientsBusiness.push(recipient)
            } else if (recipient.isMobileWallet) {
              recipientsMobileWallet.push(recipient)
            } else {
              recipientsOther.push(recipient)
            }
          }
        })
        if (recipientsOwn.length > 0) {
          const title = !isCorporateAccount.value
            ? $t('PageRecipients.CategoryMyself').value
            : $t('PageRecipients.CategoryMyBusiness').value
          categories.push({
            title: title,
            recipients: recipientsOwn,
          })
        }
        if (recipientsOther.length > 0) {
          const title = !isCorporateAccount.value
            ? $t('PageRecipients.CategoryOthers').value
            : $t('PageRecipients.CategoryOthersCorp').value

          categories.push({
            title: title,
            recipients: recipientsOther,
          })
        }
        if (recipientsCash.length > 0) {
          categories.push({
            title: $t('PageRecipients.CategoryCashPickup').value,
            recipients: recipientsCash,
          })
        }
        if (recipientsMobileWallet.length > 0) {
          categories.push({
            title: $t('PageRecipients.CategoryWallet').value,
            recipients: recipientsMobileWallet,
          })
        }

        if (recipientsBusiness.length > 0) {
          const title = !isCorporateAccount.value
            ? $t('PageRecipients.CategoryBusiness').value
            : $t('PageRecipients.CategoryOtherBusinesses').value

          categories.push({
            title: title,
            recipients: recipientsBusiness,
          })
        }
      }

      return categories
    })

    const addRecipient = () => {
      emit('add')
    }

    const viewRecipient = (recipient) => {
      emit('viewRecipient', recipient)
    }

    const deleteRecipient = (recipient) => {
      emit('deleteRecipient', recipient)
    }

    const sendMoneyRecipient = (recipient) => {
      emit('sendMoneyRecipient', recipient)
    }

    const { getRecipientCountryName } = useRecipientHelpers()

    return {
      loading,
      recipientsByCategory,
      viewRecipient,
      addRecipient,
      deleteRecipient,
      sendMoneyRecipient,
      getRecipientCountryName,
      $t,
    }
  },
}
</script>
<style scoped>
.title {
  @apply type-h3 mb-1 mt-5;
  @screen sm {
    @apply mb-1;
  }
}
.recipient-deactivated-text {
  @apply text-red-text !important;
}
.recipients-list-title {
  @apply mb-3;
  @apply tracking-wider;
}
.recipient-category-list {
  @apply border-t-0 border-b-0;
}

/* fixing hover for applist*/
::v-deep .recipient-category-list .list-item-caption {
  @apply text-xs font-normal text-tertiary-text;
}
</style>
