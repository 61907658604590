<template>
  <fieldset :disabled="recipient.deactivated" class="container">
    <DeactivatedRecipientWarning v-if="recipient.deactivated" />
    <slot> </slot>
  </fieldset>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import DeactivatedRecipientWarning from '@galileo/components/Views/Recipient/DeactivatedRecipientWarning/DeactivatedRecipientWarning'

export default defineComponent({
  components: {
    DeactivatedRecipientWarning,
  },
  props: {
    recipient: {
      type: Object,
      required: true,
    },
  },
})
</script>
<style scoped>
.container {
  display: contents;
}
</style>
