<template>
  <!-- Delete Recipient Dialog -->
  <AppDialog v-model="value">
    <template #header>
      <div>
        <XeBackButton
          class="closeButton"
          analytics-name="delete-recipient-type-close"
          icon="x"
          @click="$emit('cancel')"
        />
      </div>
      <AppDialogHeader>
        <img
          class="trashCan"
          src="@galileo/assets/images/illustrations/trash.svg"
          alt="Trash icon"
        />
        <div class="headerDelete">
          {{ $t('ComponentDeleteRecipientDialog.Header').value }}
        </div>
      </AppDialogHeader>
    </template>
    <div class="infoDelete">
      {{ $t('ComponentDeleteRecipientDialog.Title').value }}
    </div>
    <template #footer>
      <AppDialogFooter class="dialogFooter">
        <AppButton
          theme="text"
          analytics-name="delete-recipient-dialog-delete"
          :loading="confirming || loading"
          @click="confirmDeleteRecipient"
        >
          {{ $t('ComponentDeleteRecipientDialog.ButtonDelete').value }}
        </AppButton>
        <AppButton
          :disabled="loading"
          analytics-name="delete-recipient-dialog-cancel"
          @click="$emit('cancel')"
        >
          {{ $t('ComponentDeleteRecipientDialog.ButtonCancel').value }}
        </AppButton>
      </AppDialogFooter>
    </template>
  </AppDialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import { useRouter } from '@galileo/composables/useRouter'

import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'

import { AppDialog, AppDialogHeader, AppDialogFooter, AppButton, useAlert } from '@oen.web.vue2/ui'

import {
  useI18nStore,
  useAppStore,
  useRecipientsStore,
  useAuthStore,
  useSendMoneyStore,
  useQuickTransferStore,
} from '@galileo/stores'
import { loading } from '../SendMoney/Atoms/-common-props'

export default {
  name: 'DeleteRecipientDialog',
  emits: ['cancel', 'confirm'],
  components: {
    AppDialog,
    AppDialogHeader,
    AppDialogFooter,
    AppButton,
    XeBackButton,
    useAlert,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    recipient: {
      type: Object,
      required: true,
    },
    confirming: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const appStore = useAppStore()
    const recipientsStore = useRecipientsStore()
    const authStore = useAuthStore()
    const sendMoneyStore = useSendMoneyStore()
    const quickTransferStore = useQuickTransferStore()
    const router = useRouter()
    const loading = ref(false)

    const { add } = useAlert()

    const confirmDeleteRecipient = async () => {
      const userProfile = authStore.userProfile
      let profileId = userProfile.customer.id
      let recipientId = props.recipient.id
      loading.value = true

      if (props.recipient.deliveryMethod === 'CashPayout' || props.recipient.isMobileWallet) {
        try {
          await recipientsStore.deleteCashRecipient({
            recipientId: recipientId,
            isMobileWallet: props.recipient.isMobileWallet,
          })
        } catch (ex) {
          appStore.logException('Exception during delete cash recipient in recipients', ex)
          appStore.messageBoxGenericError()
          return
        }
      } else {
        try {
          await recipientsStore.deleteRecipient({ profileId, recipientId })
        } catch (ex) {
          appStore.logException('Exception during delete recipient in recipients', ex)
          appStore.messageBoxGenericError()
          return
        }
      }

      const isDeletingCurrentlySelectedRecipient = sendMoneyStore.form.recipient?.id === recipientId

      if (
        appStore.currentRoute.from.name === 'QuickTransfer' &&
        appStore.currentRoute.name !== 'Recipients'
      ) {
        if (isDeletingCurrentlySelectedRecipient) {
          sendMoneyStore.restoreDefaultForm()
        }
        router.toParentRoute()
      }
      emit('success')
      loading.value = false
      add($t('ComponentDeleteRecipientDialog.DeleteSeccessToast').value)
    }

    return {
      $t,
      confirmDeleteRecipient,
      loading,
    }
  },
}
</script>

<style scoped>
.trashCan {
  height: 32px;
  width: 32px;
  margin-bottom: 20px;
  display: inline;
}
.headerDelete {
  @apply text-center;
  @apply text-2xl;
  @apply font-medium;
}
.infoDelete {
  @apply text-center;
  @apply text-base;
  @apply font-normal;
  padding-left: 2rem;
  padding-right: 2rem;
}
.closeButton {
  position: relative;
  left: 88%;
  margin-top: 20px;
}
.dialogHeader {
  margin-top: 0rem;
}
.dialogFooter {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
}
.cardContent {
  padding-left: 4rem;
  padding-right: 4rem;
}
</style>
