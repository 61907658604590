<template>
  <div>
    <AppModal v-model="showSelf" :title="$t('ComponentEditRecipientDetailsModal.ModalTitle').value">
      <template #header>
        <AppModalHeader>
          <template #left>
            <XeBackButton
              v-if="!isMissingInformationFlow"
              icon="<"
              :name="$t('PageSendMoneyAdditionalDetails.GoBackTooltipText').value"
              analytics-name="bank-details-modal-back"
              @click="$emit('back')"
            />
          </template>
          <template #right>
            <XeBackButton
              icon="x"
              :name="$t('RecipientDetailsModal.CloseTooltipText').value"
              analytics-name="bank-details-modal-close"
              @click="$emit('close')"
            />
          </template>
          <template #default>
            {{ $t('ComponentEditRecipientDetailsModal.ModalTitle').value }}
          </template>
        </AppModalHeader>
      </template>

      <div v-if="!isLoading">
        <DeactivatedRecipientWrapper :recipient="recipient">
          <AppAlert v-if="editTransaction" class="mb-6" theme="yellow">
            {{ $t('ComponentEditRecipientDetailsModal.TransactionChangesWarning').value }}
          </AppAlert>
          <AppAlert
            v-if="(!editTransaction && !recipient.rawData.isOwnAccount) || isConfirmName"
            class="mb-6"
            theme="gray"
          >
            {{
              isBankAccount || isMobileWalletRecipient
                ? $t('ComponentEditRecipientDetailsModal.StopFraud').value
                : $t('ComponentEditRecipientDetailsModal.CashRecipientWarning').value
            }}
            <AppAlertAction v-if="isBankAccount || isMobileWalletRecipient" @click="onLearnMore">
              {{ $t('ComponentEditRecipientDetailsModal.LearnMore').value }}
            </AppAlertAction>
          </AppAlert>

          <AppInputText
            v-if="!isBankAccount && !isBusiness"
            v-model="form.recipientFirstName"
            :validation="validation.recipientFirstName"
            :label="$t('ComponentModifyCashRecipientModal.FirstNameLabel').value"
          />

          <AppInputText
            v-if="!isBankAccount && !isBusiness"
            v-model="form.recipientMiddleName"
            :label="$t('ComponentModifyCashRecipientModal.MiddleNameLabel').value"
          />

          <AppInputText
            v-if="!isBankAccount && !isBusiness"
            v-model="form.recipientLastName"
            :validation="validation.recipientLastName"
            :label="$t('ComponentModifyCashRecipientModal.LastNamelabel').value"
            :helper-text="$t('ComponentModifyCashRecipientModal.LastNameHelperText').value"
          />

          <AppInputText
            v-if="isBankAccount && !isBusiness && showFullNameField"
            v-model="form.recipientName"
            :validation="validation.name"
            :label="$t('ComponentEditRecipientDetailsModal.NameLabel').value"
            :helper-text="$t('ComponentEditRecipientDetailsModal.HelperText').value"
            class="full-name"
          >
            <template #labelRight>
              <XeTooltip activation="click" side="bottom">
                <template #wrappedElement>
                  <AppIcon name=" ">
                    <IconInformation />
                  </AppIcon>
                </template>
                <template #tooltipContent>
                  <div class="mb-6">
                    <span class="rule-list-item-title">
                      {{
                        $t(
                          'ComponentModifyRecipientModal.DialogFullNameInfo.TitleGettingTheNameRight'
                        ).value
                      }}
                    </span>
                    <p class="rule-list-item-text">
                      {{
                        $t(
                          'ComponentModifyRecipientModal.DialogFullNameInfo.TextGettingTheNameRight'
                        ).value
                      }}
                    </p>
                  </div>

                  <div class="mb-6">
                    <span class="rule-list-item-title">
                      {{
                        $t('ComponentModifyRecipientModal.DialogFullNameInfo.TitleNoTitleNeeded')
                          .value
                      }}
                    </span>
                    <p class="rule-list-item-text">
                      {{
                        $t('ComponentModifyRecipientModal.DialogFullNameInfo.TextNoTitleNeeded')
                          .value
                      }}
                    </p>
                  </div>

                  <div>
                    <span class="rule-list-item-title">
                      {{
                        $t('ComponentModifyRecipientModal.DialogFullNameInfo.TitleJointAccount')
                          .value
                      }}
                    </span>
                    <p class="rule-list-item-text">
                      {{
                        $t('ComponentModifyRecipientModal.DialogFullNameInfo.TextJointAccount')
                          .value
                      }}
                    </p>
                  </div>
                </template>
              </XeTooltip>
            </template>
          </AppInputText>

          <AppDynamicForm v-if="isBankAccount" :fields="formFields" />

          <XeInputCurrency
            v-if="isBankAccount"
            v-model="recipient.currency"
            :label="$t('ModifyRecipientModal.LabelInputCurrency').value"
            :currencies="currencyList"
            disabled
            @input="onCurrencyChange"
          />

          <AppInputCountry
            :empty-filter-message="$t('AddressSearch.noResult').value"
            v-model="recipient.country"
            :label="$t('RecipientField.countrycode.label').value"
            :countries="countryList"
            disabled
            @input="onCountryChange"
          />

          <XeInputCity
            v-if="!isBankAccount && !isBusiness"
            :value="form.recipientStateAndCity"
            :country="recipient.country"
            :label="$t('ComponentEditRecipientDetailsModal.CityStateLabel').value"
            placeholder="Type to search"
            analytics-name="search-city"
            :validation="validation.cityState"
            @select="setState"
            @clear="clear"
            @input="input"
          />
        </DeactivatedRecipientWrapper>
      </div>
      <div v-else class="flex justify-center">
        <AppSpinner :loading="isLoading" />
      </div>
      <template #footer>
        <AppModalFooter>
          <AppButton
            analytics-name="recipient-details-modal-update"
            :loading="isLoadingState"
            :disabled="
              isLoadingState ||
              validation.$anyInvalid ||
              recipient.deactivated ||
              !validCitySelected
            "
            @click="update"
          >
            {{
              editTransaction
                ? $t('ComponentEditRecipientDetailsModal.SaveButton').value
                : $t('ComponentEditRecipientDetailsModal.SaveUpdateButton').value
            }}
          </AppButton>
        </AppModalFooter>
      </template>
    </AppModal>

    <AppDialog v-model="showFraudInfo" class="fraud-tooltip">
      <AppCardImageTitle
        :title="$t('ComponentModifyRecipientModal.TitleFraudDialog').value"
        :src="require('@galileo/assets/images/illustrations/warning_xe.svg')"
        alt="Take a moment and stop fraud"
        class="m-auto"
      >
        <p>{{ $t('ComponentModifyRecipientModal.FraudDialogInfo').value }}</p>
      </AppCardImageTitle>

      <AppDialogFooter>
        <AppButton analytics-name="modify-recipient-fraud-dialog-ok" @click="onFraudGotIt">
          {{ $t('ComponentModifyRecipientModal.ButtonFraudGotIt').value }}
        </AppButton>
        <AppButton
          class="contact-us"
          analytics-name="modify-recipient-fraud-dialog-contact-us"
          theme="text"
          :loading="loadingContactUs"
          @click="onFraudContactUs"
        >
          {{ $t('ComponentModifyRecipientModal.ButtonFraudContactUs').value }}
        </AppButton>
      </AppDialogFooter>
    </AppDialog>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, ref, toRef, reactive } from '@vue/composition-api'
import { useValidation } from 'vue-composable'

import { getAllISOByCurrency } from '@galileo/composables/useIsoCountryCurrencyLibrary'

import XeInputCity from '@galileo/components/XeInputCity/XeInputCity'
import DeactivatedRecipientWrapper from '@galileo/components/Views/Recipient/DeactivatedRecipientWarning/DeactivatedRecipientWrapper'
import {
  useRecipientUpdate,
  useFullNameValidation,
  useBusinessNameValidation,
} from '@galileo/composables/useRecipientHelpers'
import XeTooltip from '@galileo/components/XeTooltip/XeTooltip'
import { IconInformation } from '@oen.web.vue2/icons'
import { useIndustryType } from '@galileo/composables/useEditBankDetails'

import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'
import XeInputCurrency from '@galileo/components/XeInputCurrency/XeInputCurrency'
import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'
import XeModalHeader from '@galileo/components/XeModalHeader/XeModalHeader'

import getValueRequiredValidation from '@galileo/utilities/validations.utility'

import {
  useI18nStore,
  useResourcesStore,
  useCountriesStore,
  useAppStore,
  useAnalyticsStore,
  useRecipientsStore,
} from '@galileo/stores'

import {
  useVModel,
  AppModal,
  AppIcon,
  AppDialogFooter,
  AppModalHeader,
  AppModalFooter,
  AppButton,
  AppInputText,
  AppInputCountry,
  AppCardImageTitle,
  AppBackButton,
  AppDialog,
  AppAlert,
  AppAlertAction,
  AppSpinner,
  AppDynamicForm,
} from '@oen.web.vue2/ui'

export default defineComponent({
  components: {
    AppModal,
    AppModalHeader,
    AppModalFooter,
    AppButton,
    XeBackButton,
    AppInputCountry,
    XeInputCurrency,
    AppInputText,
    XeModalHeader,
    AppAlert,
    AppAlertAction,
    AppDialog,
    AppCardImageTitle,
    AppBackButton,
    XeInputCity,
    DeactivatedRecipientWrapper,
    XeTooltip,
    AppIcon,
    IconInformation,
    AppDialogFooter,
    AppSpinner,
    AppDynamicForm,
  },
  //
  emits: ['update', 'success', 'back', 'close'],
  props: {
    recipient: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
    editTransaction: {
      type: Boolean,
      default: false,
    },
    isMissingInformationFlow: {
      type: Boolean,
      default: false,
    },
    isConfirmName: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSendMoney: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { model } = useVModel(props, emit)
    const showSelf = ref(model.value)
    const { $t } = useI18nStore()
    const resourcesStore = useResourcesStore()
    const countriesStore = useCountriesStore()
    const appStore = useAppStore()
    const analyticsStore = useAnalyticsStore()
    const recipientsStore = useRecipientsStore()
    const valueRequired = getValueRequiredValidation()

    const industryCategoryType = ref({})
    const { setupIndustryType, selectedIndustryType } = useIndustryType()

    const {
      fetchFormFields,
      isLoadingState,
      setFormFieldValue,
      updateRecipient,
      completeFieldsDefinition,
      addFormFieldValue,
      formFields,
      formValidation,
    } = useRecipientUpdate(props.recipient)

    const form = reactive({
      recipientName: '',
      recipientNickname: '',
      recipientFirstName: '',
      recipientLastName: '',
      recipientMiddleName: '',
      recipientCity: '',
      recipientState: '',
      recipientStateAndCity: '',
    })

    const showFullNameField = ref(true)

    const countryList = ref([])
    const currencyList = ref([])
    const showFraudInfo = ref(false)
    const loadingContactUs = ref(false)
    const isLoading = ref(false)

    const onLearnMore = () => {
      showFraudInfo.value = true
    }
    const onFraudGotIt = () => {
      showFraudInfo.value = false
    }
    const onFraudContactUs = () => {
      appStore.openHelpDesk({ loadingRef: loadingContactUs })
    }
    let isBusiness = props.recipient.isBusinessAccount
    let isBankAccount = props.recipient.isBankAccount
    let isMobileWalletRecipient = props.recipient.isMobileWallet

    const {
      containsInvalidWordsOrPrefixes,
      containsInvalidCharacters,
      containsFirstAndLastName,
      containsAtLeastTwoCharsInNames,
    } = useFullNameValidation('name', isBusiness)

    const {
      businessNameValidator,
      businessNameMaxLengthValidator,
      nicknameValidator,
      nicknameMaxLengthValidator,
      sanitizeBusinessField,
      maxNumOfCharacters,
    } = useBusinessNameValidation('name', 'nickname')

    const validation = ref({})

    onBeforeMount(async () => {
      isLoading.value = true
      countryList.value = countriesStore.getCountries
      currencyList.value = resourcesStore.getCurrenciesTo
      form.recipientName = props.recipient.accountName
      form.recipientNickname = props.recipient.nickName
      form.country = props.recipient.country

      if (!props.recipient.isBankAccount) {
        form.recipientFirstName = props.recipient.firstName
        form.recipientLastName = props.recipient.lastName
        form.recipientMiddleName = props.recipient.middleName
        form.recipientCity = props.recipient.formattedAddress.city
        form.recipientState = props.recipient.formattedAddress.state
        form.recipientStateAndCity = form.recipientCity + ', ' + form.recipientState
      }

      await fetchFormFields()

      const hasFirstNameField = formFields.value.find((field) => field.id === 'FIRST_NAME')

      if (hasFirstNameField || isBusiness || !isBankAccount) {
        showFullNameField.value = false
      }

      if (isBusiness || showFullNameField.value) {
        const fields = {
          name: {
            $value: toRef(form, 'recipientName'),
            valueRequired,
            containsInvalidWordsOrPrefixes,
            containsInvalidCharacters,
            containsFirstAndLastName,
            containsAtLeastTwoCharsInNames,
          },
          nickname: {
            $value: toRef(form, 'recipientNickname'),
            nicknameValidator,
            nicknameMaxLengthValidator,
          },
        }

        if (isBusiness) {
          fields['businessName'] = {
            $value: toRef(form, 'recipientName'),
            valueRequired,
            businessNameValidator,
            businessNameMaxLengthValidator,
          }
        }

        validation.value = useValidation(fields)
      } else if (!isBankAccount) {
        validation.value = useValidation({
          firstName: {
            $value: toRef(form, 'recipientFirstName'),
            valueRequired,
          },
          lastName: {
            $value: toRef(form, 'recipientLastName'),
            valueRequired,
          },
          city: {
            $value: toRef(form, 'recipientCity'),
            valueRequired,
          },
          cityState: {
            $value: toRef(form, 'recipientStateAndCity'),
            valueRequired,
            validStateCity: {
              $validator(v) {
                if (!v) {
                  return true
                }
                const cityStateValidationRegex = new RegExp(/^[a-zA-Z0-9\s\(\)\+\-'/\?\.:,]{1,50}$/)
                return cityStateValidationRegex.test(v)
              },
              $message: $t('ComponentModifyRecipientModal.EnterValidCityMessage').value,
            },
          },
        })
      } else {
        validation.value = formValidation.value
      }
      await getIndustryType()
      if (props.isMissingInformationFlow) {
        validation.value.$touch()
      }
      isLoading.value = false
    })

    //---------------------- Triggers
    const onCurrencyChange = async () => {
      props.recipient.country = null

      countryList.value = await recipientsStore.getCountriesForCurrency(props.recipient.currency)
      const matching = getAllISOByCurrency(props.recipient.currency)

      if (matching && matching.length > 0 && matching.length < 5) {
        props.recipient.country = matching[0]
        onCountryChange()
      }
    }

    const onCountryChange = async () => {
      fetchFormFields()
      props.recipient.bankName = ''
    }

    let recipientCity = props.recipient.recipientCity + ', ' + props.recipient.recipientState
    let validCitySelected = ref(true)

    const setState = (city) => {
      if (form.recipientState === '' && form.recipientCity === '') {
        form.recipientCity = props.recipient.recipientCity
        form.recipientState = props.recipient.recipientState
      }
      form.recipientState = city.stateName
      form.recipientCity = city.cityName
      form.recipientStateAndCity = `${form.recipientCity}, ${form.recipientState}`

      recipientCity = `${city.cityName}, ${city.stateName}`
      validCitySelected.value = true
    }

    const input = (value) => {
      form.recipientStateAndCity = value
      if (recipientCity !== value) {
        validCitySelected.value = false
      }
    }

    const clear = () => {
      form.recipientState = ''
      form.recipientCity = ''
      form.recipientStateAndCity = ''

      validCitySelected.value = false
    }

    const update = async () => {
      if (props.editTransaction) {
        const payload = {
          city: form.recipientCity,
          countryCode: props.recipient.country,
          currencyCode: props.recipient.currency,
          firstName: form.recipientFirstName,
          lastNames: form.recipientLastName,
          middleName: form.recipientMiddleName,
          state: form.recipientState,
        }

        emit('update', payload)
      } else {
        isLoadingState.value = true

        if (showFullNameField.value || !isBankAccount) {
          setFormFieldValue('ACCOUNT_NAME', form.recipientName)
        }

        let payload = null
        if (!isBankAccount) {
          payload = {
            fields: [
              {
                id: 'FirstName',
                value: form.recipientFirstName,
              },
              {
                id: 'LastNames',
                value: form.recipientLastName,
              },
              {
                id: 'MiddleName',
                value: form.recipientMiddleName,
              },
              {
                id: 'AddressCity',
                value: form.recipientCity,
              },
              {
                id: 'AddressState',
                value: form.recipientState,
              },
            ],
          }
        }
        if (isBusiness) {
          if (props.recipient?.industryTypeId) {
            addFormFieldValue('INDUSTRY_TYPE', props.recipient.industryTypeId)
          }

          const businessNameRegex = recipientsStore.getBusinessNameFieldRegex
          const businessNicknameRegex = recipientsStore.getNicknameFieldRegex

          const businessNameMaxCharacters = maxNumOfCharacters(businessNameRegex)
          const nicknameMaxCharacters = maxNumOfCharacters(businessNicknameRegex)

          const businessName = formFields.value.find((field) => field.id === 'ACCOUNT_NAME')

          const sanitizedBusinessName = sanitizeBusinessField(
            businessName.value,
            businessNameMaxCharacters
          )
          const nickName = formFields.value.find((field) => field.id === 'NICKNAME')

          if (nickName) {
            const sanitizedBusinessNickname = sanitizeBusinessField(
              nickName.value,
              nicknameMaxCharacters
            )
            setFormFieldValue('NICKNAME', sanitizedBusinessNickname)
          }

          setFormFieldValue('ACCOUNT_NAME', sanitizedBusinessName)
        }
        await updateRecipient(payload, onUpdateSucceed)
      }
    }

    const onUpdateSucceed = (recipient) => {
      if (
        !isBankAccount &&
        (form.recipientCity !== props.recipient.recipientCity ||
          form.recipientState !== props.recipient.recipientState)
      ) {
        analyticsStore.track({
          event: SEGMENT_EVENTS.PAYOUT_LOCATION_CHANGED,
          traits: {
            newDestinationCity: form.recipientCity,
            newDestinationState: form.recipientState,
            oldDestinationCity: props.recipient.recipientCity,
            oldDestinationState: props.recipient.recipientState,
          },
        })
      } else {
        analyticsStore.track({
          event: SEGMENT_EVENTS.RECIPIENT_INFO_ADDED,
          traits: {
            location: props.isSendMoney
              ? SEGMENT_LOCATIONS.SEND_MONEY
              : SEGMENT_LOCATIONS.RECIPIENT_LIST,
            recipientCountry: form.country,
          },
        })
      }
      model.value = false
      showSelf.value = false
      emit('success', recipient)
    }

    const getIndustryType = async () => {
      let industryType = selectedIndustryType.value
      if (props.recipient) {
        industryType = props.recipient.industryTypeId || industryType
      }
      industryCategoryType.value = await setupIndustryType(
        completeFieldsDefinition.value,
        props.recipient.isBusinessAccount,
        industryType
      )
    }

    return {
      model,
      update,
      countryList,
      currencyList,
      isLoadingState,
      onCurrencyChange,
      onCountryChange,
      validation,
      form,
      isBusiness,
      showFraudInfo,
      loadingContactUs,
      onLearnMore,
      onFraudGotIt,
      onFraudContactUs,
      isBankAccount,
      isMobileWalletRecipient,
      $t,
      showSelf,
      setState,
      clear,
      input,
      validCitySelected,
      isLoading,
      formFields,
      formValidation,
      showFullNameField,
    }
  },
})
</script>

<style scoped>
.card-footer > .button {
  width: 100%;
}
::v-deep .card-content {
  .card-content-block {
    @apply mb-6 mt-0;
  }
}
.alert--gray {
  @apply bg-gray-lighter border-gray-lighter;
  .alert-action {
    @apply text-secondary-text;
  }
}
::v-deep .card-header--without-title {
  padding-bottom: 0;
}
::v-deep .dialog .dialog--card.card .card-content {
  padding-left: 3rem;
  padding-right: 3rem;
}
::v-deep .card-image-title .card-image-title-image {
  margin-bottom: 0;
}
.contact-us {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.rule-list-item-title {
  @apply font-medium;
}
.rule-list-item-text {
  @apply font-normal mt-2;
}
.full-name {
  ::v-deep .tooltip-wrapper {
    @apply relative text-gray-dark mx-1;
    bottom: -6px;

    > div:not(.tooltip-tip) {
      @apply flex justify-center;
    }
  }
}
</style>
