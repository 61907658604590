<template>
  <div>
    <!-- Selected recipient modal -->
    <AppModal
      v-show="
        !showHideEditBankModal && !showHideEditAddressModal && !showHideEditRecipientDetailsModal
      "
      v-model="model"
      @hidden="$emit('onHidden')"
      :title="$t('ComponentRecipientDetailsModal.TitleModal').value"
    >
      <template #header>
        <AppModalHeader>
          <template #left>
            <XeBackButton
              icon="<"
              :name="$t('RecipientDetailsModal.CloseTooltipText').value"
              analytics-name="card-details-modal-back"
              @click="goBackToRecipients"
            />
          </template>

          <template #default>
            <h1 class="modal-title">
              {{ $t('ComponentRecipientDetailsModal.ViewRecipientTitle').value }}
            </h1>
          </template>

          <template #right>
            <AppButton
              theme="icon"
              class="icon icon-trash-can"
              :name="$t('RecipientDetailsModal.DeleteTooltipText').value"
              analytics-name="recipient-details-modal-edit"
              @click="$emit('delete', recipient)"
            >
              <AppIcon :name="$t('RecipientDetailsModal.DeleteTooltipText').value">
                <IconTrashCan />
              </AppIcon>
            </AppButton>
          </template>
        </AppModalHeader>
      </template>

      <div v-if="recipient">
        <RecipientDetail
          :title="$t('ComponentRecipientDetailsModal.TitleRecipientDetails').value"
          :bottom-border="recipient.isBankAccount || recipient.isMobileWallet"
          @edit="editRecipientDetails"
        >
          <div class="grid">
            <EllipsisWrapper
              v-if="recipient.accountName && recipient.isBusinessAccount"
              :value="recipient.accountName"
            >
              <h3>
                {{ recipient.accountName }}
              </h3>
            </EllipsisWrapper>

            <EllipsisWrapper
              v-else-if="recipient.firstName || recipient.lastName"
              :value="recipient.fullName"
            >
              <h3>{{ recipient.firstName }} {{ recipient.middleName }} {{ recipient.lastName }}</h3>
            </EllipsisWrapper>

            <EllipsisWrapper v-if="recipient.nickName" :value="recipient.nickName">
              <h3>{{ recipient.nickName }}</h3>
            </EllipsisWrapper>
          </div>

          <h3 v-if="!recipient.isMobileWallet">
            {{ getRecipientCountryName(recipient) }}, {{ recipient.currency }}
          </h3>

          <h3 v-if="!recipient.isBankAccount">
            {{ recipient.formattedAddress.city }}, {{ getRecipientCountryName(recipient) }}
          </h3>
          <h3 v-if="recipient.taxNumber">
            {{ recipient.taxNumber }}
          </h3>
        </RecipientDetail>

        <RecipientDetail
          v-if="isMobileWallet && recipient.mobileWalletCorrespondentName"
          :show-edit-button="false"
          :title="$t('ComponentRecipientDetailsModal.TitleRecipientWalletDetails').value"
          :bottom-border="true"
        >
          <h3>{{ recipient.mobileWalletCorrespondentName }}</h3>
          <h3>
            <span v-if="recipient.mobileWalletPrefix">{{ recipient.mobileWalletPrefix }}</span>
            {{ recipient.mobileWalletAccountNo }}
          </h3>
        </RecipientDetail>

        <RecipientDetail
          v-if="recipient.isBankAccount"
          :title="$t('ComponentRecipientDetailsModal.TitleRecipientBankDetails').value"
          :bottom-border="shouldDisplayAddressFields"
          @edit="editBankDetails"
        >
          <h3>{{ recipient.bankIban }}</h3>
          <h3>{{ recipient.bankAccountNumber }}</h3>
          <h3>{{ recipient.ibanOrAccountNumber }}</h3>
          <h3>{{ recipient.bankBicSwift }}</h3>
          <h3>{{ recipient.bankName }}</h3>
        </RecipientDetail>

        <RecipientDetail
          v-if="recipient.isBankAccount && shouldDisplayAddressFields"
          :title="$t('ComponentRecipientDetailsModal.TitleRecipientAddressDetails').value"
          @edit="editAddressDetails"
        >
          <h3>{{ getRecipientCountryName(recipient, 'addressCountry') }}</h3>
          <h3>{{ recipient.formattedAddress.street }}</h3>
          <h3>{{ recipient.addressLine2 }}</h3>
          <h3>{{ formatState(recipient.state) }}</h3>
          <h3
            v-if="
              recipient.formattedAddress.city &&
              recipient.formattedAddress.city !== recipient.addressLine2
            "
          >
            {{ recipient.formattedAddress.city }}
          </h3>
          <h3>{{ recipient.formattedAddress.postalCode }}</h3>
        </RecipientDetail>
      </div>
      <div v-else class="flex justify-center">
        <AppSpinner :loading="!recipient || initialLoading" />
      </div>
      <template #footer>
        <AppModalFooter>
          <AppButton
            class="full-width"
            :loading="loadingContinue"
            analytics-name="recipient-details-modal-confirm"
            :disabled="recipient && recipient.deactivated"
            @click="$emit('confirm', recipient)"
          >
            {{
              confirmText ? confirmText : $t('ComponentRecipientDetailsModal.ButtonContinue').value
            }}
          </AppButton>
        </AppModalFooter>
      </template>
    </AppModal>

    <EditBankDetailsModal
      v-if="recipient && showHideEditBankModal"
      v-model="showHideEditBankModal"
      :recipient="recipient"
      @success="onSuccessAlertAndReloadRecipient"
      @close="closeAll"
      @back="hideAll"
    />

    <EditRecipientDetailsModal
      v-if="recipient && showHideEditRecipientDetailsModal"
      v-model="showHideEditRecipientDetailsModal"
      :recipient="recipient"
      @close="closeAll"
      @back="hideAll"
      @success="onSuccessAlertAndReloadRecipient"
      :isSendMoney="false"
    />

    <EditAddressDetailsModal
      v-if="recipient && showHideEditAddressModal"
      v-model="showHideEditAddressModal"
      :recipient="recipient"
      @close="closeAll"
      @back="hideAll"
      @success="onSuccessAlertAndReloadRecipient"
    />

    <SendMoneyRecipientMissingInformationModal
      :errors="recipientValidationErrors"
      :missing-information="showMissingInformationModal"
      @continue="editRecipientWithMissingInformation"
      @close="closeMissingInformationModal"
    />

    <EditMissingRecipientInformation
      v-if="showEditMissingRecipientInformation"
      :key="recipient.id"
      :recipient-validation-errors="recipientValidationErrors"
      :selected-recipient="recipient"
      @reset="resetEditMissingInformation"
      @reload-recipient="getRecipientDetails"
    />
  </div>
</template>

<script>
import { onBeforeMount, ref } from '@vue/composition-api'

import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'
import { maskFormatAbbreviated } from '@galileo/composables/useMaskHelpers'
import RecipientDetail from '@galileo/components/Views/Recipient/RecipientDetailsListFigure/RecipientDetailListFigure'

//---------------- EDIT
import EditBankDetailsModal from '@galileo/components/Views/Recipient/EditBankDetailsModal/EditBankDetailsModal'
import EditAddressDetailsModal from '@galileo/components/Views/Recipient/EditAddressDetailsModal/EditAddressDetailsModal'
import EditRecipientDetailsModal from '@galileo/components/Views/Recipient/EditRecipientDetailsModal'

import { IconTrashCan } from '@oen.web.vue2/icons'
import EllipsisWrapper from '@galileo/components/Views/Recipient/EllipsisWrapper.vue'

import {
  useVModel,
  useAlert,
  AppModal,
  AppModalHeader,
  AppModalFooter,
  AppButton,
  AppIcon,
  AppSpinner,
} from '@oen.web.vue2/ui'

import SendMoneyRecipientMissingInformationModal from '@galileo/components/Views/SendMoney/SendMoneyRecipientMissingInformationModal'
import EditMissingRecipientInformation from '@galileo/components/Views/Recipient/EditMissingRecipientInformation'

import {
  useRecipientValidation,
  useRecipientHelpers,
} from '@galileo/composables/useRecipientHelpers'

import { useI18nStore, useRecipientsStore, useCountriesStore, useAuthStore } from '@galileo/stores'

export default {
  name: 'RecipientDetailsModal',
  emits: ['input', 'edit', 'editBankAccount', 'confirm', 'onHidden', 'delete', 'goBack'],
  components: {
    EllipsisWrapper,
    AppSpinner,
    AppModal,
    AppModalHeader,
    AppModalFooter,
    AppButton,
    AppIcon,
    IconTrashCan,
    XeBackButton,
    EditBankDetailsModal,
    EditAddressDetailsModal,
    EditRecipientDetailsModal,
    RecipientDetail,
    SendMoneyRecipientMissingInformationModal,
    EditMissingRecipientInformation,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    selectedRecipient: {
      type: Object,
      default: null,
    },
    loadingContinue: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit, listeners }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)
    const { getRecipientCountryName } = useRecipientHelpers()
    const recipientsStore = useRecipientsStore()
    const authStore = useAuthStore()
    const countriesStore = useCountriesStore()

    //------------------ Fetch -------------------------------

    const {
      showMissingInformationModal,
      showEditMissingRecipientInformation,
      recipientValidationErrors,
      isRecipientValid,
      editRecipientWithMissingInformation,
      resetEditMissingInformation,
      closeMissingInformationModal,
    } = useRecipientValidation()

    const recipient = ref(null)
    const isCashRecipient = ref(props.selectedRecipient.deliveryMethod === 'CashPayout')
    const isMobileWallet = ref(props.selectedRecipient.deliveryMethod === 'MobileWallet')
    const isBankAccount = ref(props.selectedRecipient.deliveryMethod === 'BankAccount')
    const stateList = ref([])

    //get recipients details based on the recipient we get from props
    //so we separate the component from the containing component

    const getRecipientDetails = async (validate = false) => {
      let recipientId = props.selectedRecipient.id

      if (!recipientId && recipient) {
        recipientId = recipient.value.id
      }

      if (isBankAccount.value) {
        recipient.value = await recipientsStore.getRecipient(recipientId)
        if (recipient.value.addressCountry) {
          stateList.value = await countriesStore.getStatesByCountryCode(
            recipient.value.addressCountry
          )
        }
        if (recipient.value.addressCountry) {
          shouldDisplayAddressFields.value = true
        } else {
          shouldDisplayAddressFields.value = false
        }
      } else if (isCashRecipient.value) {
        recipient.value = await recipientsStore.getCashRecipient(recipientId)
      } else {
        recipient.value = await recipientsStore.getMobileWalletRecipient(recipientId)
      }

      if (validate) {
        const isValid = await isRecipientValid(recipient.value)
      }
    }

    const onSuccessAlertAndReloadRecipient = async () => {
      const { add } = useAlert()
      add(`${$t('RecipientDetailsModal.SaveSuccessfulAlertText').value}`)
      await getRecipientDetails()
    }

    const initialLoading = ref(false)

    onBeforeMount(async () => {
      initialLoading.value = true
      await getRecipientDetails(true)

      const userId = authStore.user.customer.id

      recipientsStore.getRecipientFieldsRegex({
        id: userId,
        country: recipient.value.country,
        currency: recipient.value.currency,
      })

      initialLoading.value = false
    })

    const shouldDisplayAddressFields = ref(true)

    const formatState = (state) => {
      let stateObject = stateList.value?.find((el) => {
        return el.value === recipient.value.formattedAddress.state
      })

      if (stateObject) {
        return stateObject.name
      }

      return state
    }

    //-------------- Edit ------------------

    const showHideEditBankModal = ref(false)
    const editBankDetails = async () => {
      showHideEditBankModal.value = true
    }
    const showHideEditRecipientDetailsModal = ref(false)
    const editRecipientDetails = () => {
      showHideEditRecipientDetailsModal.value = true
    }
    const closeAll = () => {
      emit('goBack')
      showHideEditBankModal.value = false
      showHideEditAddressModal.value = false
      showHideEditRecipientDetailsModal.value = false
      model.value = false
    }
    const hideAll = () => {
      showHideEditBankModal.value = false
      showHideEditAddressModal.value = false
      showHideEditRecipientDetailsModal.value = false
    }

    const showHideEditAddressModal = ref(false)
    const editAddressDetails = async () => {
      showHideEditAddressModal.value = true
    }

    const goBackToRecipients = () => {
      emit('goBack')
      document.body.classList.remove('overflow-hidden')
      model.value = false
    }

    return {
      model,
      $t,
      getRecipientCountryName,
      maskFormatAbbreviated,
      isCashRecipient,
      isMobileWallet,
      shouldDisplayAddressFields,
      initialLoading,
      // ---------------- EDIT MISSING INFO
      showMissingInformationModal,
      showEditMissingRecipientInformation,
      editRecipientWithMissingInformation,
      closeMissingInformationModal,
      recipientValidationErrors,
      resetEditMissingInformation,
      //---------------- EDIT
      showHideEditBankModal,
      editBankDetails,
      showHideEditAddressModal,
      editAddressDetails,
      getRecipientDetails,
      closeAll,
      hideAll,
      onSuccessAlertAndReloadRecipient,
      //-------------------------------
      recipient,
      showHideEditRecipientDetailsModal,
      editRecipientDetails,
      goBackToRecipients,
      formatState,
    }
  },
}
</script>

<style scoped>
.full-width {
  @apply w-full !important;
}

::v-deep .card-footer,
.full-width {
  @apply w-full;
}

::v-deep .card-header {
  @apply px-12;
}

.icon-trash-can.button.button--icon {
  @screen sm {
    @apply w-6 h-6 !important;

    &:hover:not(:disabled) {
      @apply bg-transparent;
    }

    ::v-deep .button-inner {
      @apply w-6 h-6;
      @apply text-icon-primary;

      &:hover {
        @apply text-gray-darker;
      }
    }
  }
}

.icon-trash-can.button {
  @apply w-auto !important;
}
</style>
