<template>
  <InformationAppModal :value="shouldShowModal" icon="editDocument" @close="$emit('close')">
    <h1 class="title">{{ $t('RecipientMissingInformationModal.Title').value }}</h1>

    <!-- <h1 class="title">Recipient details missing</h1> -->

    <p>{{ $t('RecipientMissingInformation.Text').value }}</p>

    <!-- <p>To send money to this recipient we will need some additional information</p> -->

    <template #footer>
      <AppButton @click="$emit('continue')">
        {{ $t('RecipientMissingInformation.ContinueButtonText').value }}
      </AppButton>

      <AppButton v-if="isSendMoney" theme="secondary" @click="$emit('close')">
        {{ $t('RecipientMissingInformation.ChangeRecipientButtonText').value }}
      </AppButton>
      <AppButton v-else-if="canCancel" theme="secondary" @click="$emit('close')">
        {{ $t('PageLoginNewTerms.CancelDialog.CancelButton').value }}
      </AppButton>
    </template>
  </InformationAppModal>
</template>

<script>
import { defineComponent, ref, computed } from '@vue/composition-api'
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'

import { useI18nStore } from '@galileo/stores'

import { AppButton } from '@oen.web.vue2/ui'

export default defineComponent({
  components: {
    InformationAppModal,
    AppButton,
  },
  props: {
    missingInformation: {
      type: Boolean,
      required: true,
    },
    isSendMoney: {
      type: Boolean,
      default: false,
    },
    canCancel: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { $t } = useI18nStore()

    const shouldShowModal = computed(() => props.missingInformation)

    return { shouldShowModal, $t }
  },
})
</script>

<style scoped>
::v-deep .card {
  .card-header {
    @apply pt-12 pb-8;
  }

  .card-content {
    .card-content-block {
      @apply mb-8;
      .modal-body {
        p {
          @apply mt-4;
        }
      }
    }
  }
}
::v-deep .modal-body {
  .p {
    @apply mt-4;
  }
}
</style>
