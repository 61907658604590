import { computed, ref, reactive } from '@vue/composition-api'
import { useValidation } from 'vue-composable'

import { DynamicFormField } from '@oen.web.vue2/ui'

import {
  useFieldComponentHelper,
  useFieldPropsHelper,
  useFieldValidation,
} from '@galileo/composables/useFormHelpers'
import { useDynamicForm } from '@galileo/forms/useDynamicForm'

import { useI18nStore, useRecipientsStore } from '@galileo/stores'

const createDynamicFormField = (field, defaultValues) => {
  let disabled = !!field.readonly

  const i18nStore = useI18nStore()

  let defaultValue =
    defaultValues && defaultValues[field.id] ? defaultValues[field.id] : field.value ?? ''
  if (field.id === 'CountryCode') {
    field.placeholder = i18nStore.$t(
      'ComponentModifyCashRecipientModal.CountryInputPlaceholder'
    ).value
    field.type = 'country'
    // MARTIN: WHY DID YOU COMMENT THIS OUT? Seems to be working correctly for disabling when needed.
    if (defaultValue) {
      disabled = true
    }
  }
  if (field.id === 'AddressCity') {
    field.type = 'city'
    field.hidden = true
    if (!defaultValues['CountryCode']) {
      disabled = true
    }
  } else if (field.id === 'AddressState') {
    field.type = 'optionList'
    field.hidden = true
    field.options = [{}] // important to have empty options otherwise no ref will be created in useFieldPropsHelper
    if (!defaultValues['CountryCode']) {
      disabled = true
    }
  } else if (field.id === 'CurrencyCode') {
    field.hidden = true
  } else if (field.id === 'LastNames') {
    field.label = i18nStore.$t('ComponentModifyCashRecipientModal.LastNamelabel').value
    field.helperText = i18nStore.$t('ComponentModifyCashRecipientModal.LastNameHelperText').value
  } else if (field.id === 'MiddleName') {
    field.label = i18nStore.$t('ComponentModifyCashRecipientModal.MiddleNameLabel').value
    field.placeholder = i18nStore.$t(
      'ComponentModifyCashRecipientModal.MiddleNamePlaceholder'
    ).value
  } else if (field.id === 'FirstName') {
    field.label = i18nStore.$t('ComponentModifyCashRecipientModal.FirstNameLabel').value
  }

  if (['FirstName', 'MiddleName', 'LastNames'].includes(field.id)) {
    const regexValidationIndex = field.validations.findIndex((vld) => vld.type == 'Regex')
    const regexValidationString = field.validations[regexValidationIndex].value
    field.validations[regexValidationIndex].value = regexValidationString.replaceAll(
      `a-zA-Z`,
      `a-zA-Z\\p{Script=Latin}`
    )
  }

  const dynamicFormField = new DynamicFormField({
    field,
    value: defaultValue,
    hidden: field.hidden,
    disabled: disabled,
    props: useFieldPropsHelper(field),
    component: useFieldComponentHelper(field.type),
  })
  dynamicFormField.props.helperText = field.helperText
  if (field.id === 'AddressCity') {
    dynamicFormField.props.country = ref(defaultValues['CountryCode'])
  }

  return reactive(dynamicFormField)
}

export const useCashRecipientForm = () => {
  const { formValidation, formFields, hasFormFields, isFormInvalid } = useDynamicForm()
  const { createFormFieldValidation } = useFieldValidation()
  const recipientsStore = useRecipientsStore()

  const setup = (fields, defaultValues = {}) => {
    const fieldData = []
    const validationData = {}
    const sortedFields = {
      FirstName: null,
      MiddleName: null,
      LastNames: null,
      CountryCode: null,
      AddressCity: null,
      AddressState: null,
    }

    for (const field of fields) {
      const formField = createDynamicFormField(field, defaultValues)
      validationData[formField.name] = createFormFieldValidation(formField)

      // Move prioritised countries to the top of the country list
      if (formField.id === 'CountryCode') {
        formField.props.countries = formField.props.countries.slice()
        const prioritisedCountries = ['US', 'GB', 'CA', 'AU', 'NZ', 'DE', 'FR']
        prioritisedCountries.forEach((countryCode) => {
          const countryIndex = formField.props.countries.findIndex(
            (item) => item.value === countryCode
          )
          if (countryIndex !== -1) {
            const prioritisedCountry = formField.props.countries.splice(countryIndex, 1)[0]
            const insertionIndex = prioritisedCountries.indexOf(countryCode)
            formField.props.countries.splice(insertionIndex, 0, prioritisedCountry)
          }
        })
      }

      sortedFields[field.id] = formField
    }

    for (const key in sortedFields) {
      const field = sortedFields[key]
      if (field) {
        fieldData.push(field)
      }
    }

    formValidation.value = useValidation(validationData)

    for (const formField of fieldData) {
      if (formField.id === 'CountryCode') {
        formField.listeners = {
          input: async (value) => {
            const city = sortedFields['AddressCity']
            const state = sortedFields['AddressState']
            city.disabled = false
            city.props.country.value = value

            const statesPromise = await recipientsStore.getStates(value)
            state.disabled = false
            state.props.options = statesPromise.map((option) => {
              return { text: option.name, value: option.value }
            })
          },
        }
        if (defaultValues['CountryCode']) {
          formField.listeners.input(defaultValues['CountryCode'])
        }
      }
      if (formField.id === 'AddressCity') {
        formField.listeners = {
          select: (value) => {
            sortedFields['AddressState'].value = value.state
          },
        }
      }
      /*
      if (formField.id === 'customerRecipientRelationshipName') {
        formField.hidden = true
      }
      if (formField.id === 'customerRecipientRelationshipId') {
        formField.listeners = {
          input: (value) => {
            sortedFields['customerRecipientRelationshipName'].hidden = value !== '14236'
          },
        }
      }
      */

      formField.validation = formValidation.value[formField.name]
      if (formField.parent) {
        formField.parent = formField.parent = fieldData.find((item) => item.id === formField.parent)
      }
    }

    formFields.value = fieldData
  }

  return {
    setup,
    hasFormFields,
    isFormInvalid,
    formFields: computed(() => formFields.value),
    formValidation: computed(() => formValidation.value),
  }
}
