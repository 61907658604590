<template>
  <InformationAppModal
    :value="value"
    button-title="Confirm"
    @close="closeModal"
    icon="warningYellow"
  >
    <!-- <h1 class="title">Confirm recipient details</h1> -->
    <h1 class="title">{{ $t('ConfirmNameSplit.CofirmTitle').value }}</h1>

    <p class="modal-body mb-7">
      <!-- We updated recipient names. <br /> -->
      {{ $t('ConfirmNameSplit.RecipientNameUpdateParagraph').value }} <br />

      <!-- Please confirm if they are correct -->
      {{ $t('ConfirmNameSplit.RecipientNameConfirmParagraph').value }}
    </p>

    <section>
      <XeAppDescriptionList>
        <AppDescriptionListItem>
          <template #title>{{ $t('EditNameModal.FirstNameLabel').value }}</template>
          <template #content>
            <p>{{ recipient.firstName }}</p>
          </template>
        </AppDescriptionListItem>
        <AppDescriptionListItem>
          <template #title>{{ $t('EditNameModal.LastNamesLabel').value }}</template>
          <template #content>
            <p>{{ recipient.lastName }}</p>
          </template>
        </AppDescriptionListItem>
      </XeAppDescriptionList>
    </section>

    <template #footer>
      <AppButton @click="confirmDetails" :loading="isLoading">
        <!-- Confirm details  -->
        {{ $t('ConfirmNameSplit.ConfirmDetailsButton').value }}</AppButton
      >
      <AppButton theme="secondary" @click="editDetails" :disabled="isLoading">
        <!-- Edit details -->
        {{ $t('ConfirmNameSplit.EditDetailsButton').value }}
      </AppButton>
    </template>
  </InformationAppModal>
</template>

<script>
import { computed, onBeforeMount, ref, watch } from '@vue/composition-api'

import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'

import { AppButton, AppDescriptionListItem } from '@oen.web.vue2/ui'
import { useRecipientUpdate } from '@galileo/composables/useRecipientHelpers/index.js'

import XeAppDescriptionList from '@galileo/components/XeAppDescriptionList/XeAppDescriptionList'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'

import { useI18nStore, useAnalyticsStore } from '@galileo/stores'

export default {
  components: {
    InformationAppModal,
    AppButton,
    XeAppDescriptionList,
    AppDescriptionListItem,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },
    recipient: {
      type: Object,
      required: true,
    },
    isSendMoney: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isLoading = ref(false)
    const { $t } = useI18nStore()
    const analyticStore = useAnalyticsStore()

    const closeModal = () => {
      emit('input', false)
    }

    const {
      fetchFormFields,
      isLoadingState,
      setFormFieldValue,
      formValidation,
      formFields,
      updateRecipient,
      additionalFields,
      completeFieldsDefinition,
      selectFormFields,
      addFormFieldValue,
      recipientFormAddressCountry,
      allFields,
    } = useRecipientUpdate(props.recipient, props.isMissingInformationFlow)

    const confirmDetailsAnalytics = ({ confirm = false }) => {
      analyticStore.track({
        event: SEGMENT_EVENTS.RECIPIENT_DETAILS_CONFIRMED,
        traits: {
          editDetailsClicked: !confirm,
          confirmDetails: confirm,
          location: props.isSendMoney ? 'sendMoney' : 'recipient-list',
        },
      })
    }

    const confirmDetails = async () => {
      isLoading.value = true
      try {
        await updateRecipient(
          {
            currency: props.recipient.currency,
            country: props.recipient.country,
            isOwnAccount: props.recipient.isOwnAccount,
            isBusiness: props.recipient.isBusiness,
            nameVerified: true,
            fields: allFields.value,
          },
          confirmCallback
        )
        confirmDetailsAnalytics({ confirm: true })
      } catch (ex) {
        console.error(ex)
        appStore.messageBoxGenericError()
      } finally {
        //send segment event Recipient Details Confirmed
        isLoading.value = false
      }
    }

    const confirmCallback = () => {
      closeModal()
      emit('confirm')
    }
    const editDetails = async () => {
      isLoading.value = true
      closeModal()
      confirmDetailsAnalytics({ confirm: false })
      emit('edit')
    }

    onBeforeMount(async () => {
      //segm,ent recipient details confirm started
      analyticStore.track({
        event: SEGMENT_EVENTS.RECIPIENT_DETAILS_CONFIRM_STARTED,
        traits: {
          location: props.isSendMoney ? 'sendMoney' : 'recipient-list',
        },
      })
      isLoading.value = true
      await fetchFormFields()
      isLoading.value = false
    })

    return {
      closeModal,
      confirmDetails,
      editDetails,
      $t,
      isLoading,
    }
  },
}
</script>

<style scoped>
::v-deep .description-list-item .description-list-item-term {
  font-weight: 400;
  @apply text-secondary-text;
}
::v-deep .description-list-item-definition {
  font-weight: 600;
  @apply text-primary-text;
}
</style>
