<template>
  <div class="container">
    <h1 class="title">
      {{ $t('DeactivatedRecipientWarning.Title').value }}
    </h1>
    <!-- <h1 class="title">Changes unavailable online</h1> -->

    <p class="text">
      {{ $t('DeactivatedRecipientWarning.Text').value }}
    </p>
    <!-- <p class="text">
      You can no longer send money to this recipient. Contact us to make any changes.
    </p> -->
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { useI18nStore } from '@galileo/stores'
export default defineComponent({
  setup() {
    const { $t } = useI18nStore()
    return { $t }
  },
})
</script>

<style scoped>
.container {
  @apply py-4 text-center;
}
.title {
  @apply text-red-text;
}

.text {
  @apply text-secondary-text;
}
</style>
