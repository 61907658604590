<template>
  <div>
    <AppModal v-model="showSelf" title="Edit bank">
      <template #header>
        <AppModalHeader>
          <template #left>
            <XeBackButton
              v-if="!isMissingInformationFlow"
              :name="$t('PageSendMoneyAdditionalDetails.GoBackTooltipText').value"
              icon="<"
              analytics-name="bank-details-modal-back"
              @click="$emit('back')"
            />
          </template>
          <template #default> {{ $t('EditBankDetailsModal.Title').value }} </template>
          <template #right>
            <XeBackButton
              :name="$t('RecipientDetailsModal.CloseTooltipText').value"
              icon="x"
              analytics-name="bank-details-modal-back"
              @click="$emit('close')"
            />
          </template>
        </AppModalHeader>
      </template>
      <div>
        <DeactivatedRecipientWrapper :recipient="recipient">
          <AppDynamicForm :fields="formFields" />

          <!-- Need a scenarion where there are additional fields to appear.  -->
          <div v-if="additionalFields.length > 0">
            <div>{{ $t('ModifyRecipientModal.AdditionalDetails').value }}</div>
            <AppDynamicForm :fields="additionalFields"></AppDynamicForm>
          </div>
          <div v-if="industryCategoryType.show">
            <AppInputDropdown
              v-model="industryCategoryType.selectedCategory"
              :label="$t('ModifyRecipientModal.LabelIndustryCategory').value"
              :placeholder="$t('ModifyRecipientModal.InputIndustryCategory').value"
              :options="industryCategoryType.categories"
              :validation="industryCategoryType.validation.industryCategory"
              analytics-name="industry-category-dropdown"
              selected-display-field="description"
              selected-value-field="categoryId"
            />
            <AppInputDropdown
              v-if="
                industryCategoryType.selectedCategory &&
                industryCategoryType.selectedCategory !== ''
              "
              v-model="industryCategoryType.selectedType"
              :label="$t('ModifyRecipientModal.LabelIndustryType').value"
              :placeholder="$t('ModifyRecipientModal.InputIndustryType').value"
              :options="industryCategoryType.filteredTypes"
              :validation="industryCategoryType.validation.industryType"
              analytics-name="industry-type-dropdown"
              selected-display-field="name"
              selected-value-field="industryTypeId"
            />
          </div>
        </DeactivatedRecipientWrapper>
      </div>

      <template #footer>
        <AppModalFooter>
          <AppButton
            analytics-name="bank-details-modal-update"
            :loading="isLoadingState"
            :disabled="
              formValidation.$anyInvalid ||
              (industryCategoryType.show && industryCategoryType.validation.$anyInvalid) ||
              isLoadingState ||
              recipient.deactivated
            "
            @click="update"
          >
            {{ $t('EditBankDetailsModal.SaveAndUpdateButton').value }}
          </AppButton>
        </AppModalFooter>
      </template>
    </AppModal>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'

import { getAllISOByCurrency } from '@galileo/composables/useIsoCountryCurrencyLibrary'
import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'
import XeInputCurrency from '@galileo/components/XeInputCurrency/XeInputCurrency'
import ZarWarningModal from '@galileo/components/Views/Recipient/ZARWarningModal/ZarWarningModal'
import DeactivatedRecipientWrapper from '@galileo/components/Views/Recipient/DeactivatedRecipientWarning/DeactivatedRecipientWrapper'
import { useRecipientUpdate } from '@galileo/composables/useRecipientHelpers'

import { useIndustryType } from '@galileo/composables/useEditBankDetails'

import {
  useI18nStore,
  useResourcesStore,
  useCountriesStore,
  useRecipientsStore,
} from '@galileo/stores'

import {
  useVModel,
  AppModal,
  AppModalHeader,
  AppModalFooter,
  AppButton,
  AppInputCountry,
  AppDynamicForm,
  AppInputDropdown,
} from '@oen.web.vue2/ui'

export default defineComponent({
  emits: ['close', 'success'],
  components: {
    AppModal,
    AppModalHeader,
    AppModalFooter,
    AppButton,
    XeBackButton,
    AppInputCountry,
    XeInputCurrency,
    AppDynamicForm,
    AppInputDropdown,
    ZarWarningModal,
    DeactivatedRecipientWrapper,
  },
  props: {
    recipient: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
    isMissingInformationFlow: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { model } = useVModel(props, emit)

    const showSelf = ref(model.value)
    const { $t } = useI18nStore()
    const countriesStore = useCountriesStore()
    const recipientsStore = useRecipientsStore()

    const countryList = ref([])
    //----- currency and country
    const currencyList = ref([])

    // ----------- industry type -----------
    const { setupIndustryType, selectedIndustryType } = useIndustryType()

    const industryCategoryType = ref({})

    // ---------------------- Fetch ---------------
    onBeforeMount(async () => {
      countryList.value = countriesStore.getCountries
      const resourcesStore = useResourcesStore()
      currencyList.value = resourcesStore.getCurrenciesTo
      await fetchFormFields()
      await getIndustryType()
      selectFormFields('bankFields')
    })

    //---------------------- Triggers
    const onCurrencyChange = async () => {
      props.recipient.country = null
      countryList.value = await recipientsStore.getCountriesForCurrency(props.recipient.currency)
      const matching = getAllISOByCurrency(props.recipient.currency)

      if (matching && matching.length > 0 && matching.length < 5) {
        props.recipient.country = matching[0]
        onCountryChange()
      }
    }

    const onCountryChange = async () => {
      fetchFormFields()
      props.recipient.bankName = ''
    }

    // -------------------- Fetch form fields ------------------
    const {
      fetchFormFields,
      isLoadingState,
      formValidation,
      formFields,
      updateRecipient,
      additionalFields,
      completeFieldsDefinition,
      addFormFieldValue,
      selectFormFields,
    } = useRecipientUpdate(props.recipient, props.isMissingInformationFlow)

    //---------------------- Industry Type ----------
    const getIndustryType = async () => {
      let industryType = selectedIndustryType.value
      if (props.recipient) {
        industryType = props.recipient.industryTypeId || industryType
      }
      industryCategoryType.value = await setupIndustryType(
        completeFieldsDefinition.value,
        props.recipient.isBusinessAccount,
        industryType
      )
    }

    //-------------------- Finishing Update --------------

    const update = async () => {
      isLoadingState.value = true
      //if industry type is shown we add the value to send to backend
      if (industryCategoryType.value.show) {
        addFormFieldValue('INDUSTRY_TYPE', industryCategoryType.value.selectedType)
      } else if (props.recipient?.industryTypeId) {
        addFormFieldValue('INDUSTRY_TYPE', props.recipient?.industryTypeId)
      }

      await updateRecipient(null, onUpdateSucceed)
    }

    const onUpdateSucceed = () => {
      model.value = false
      showSelf.value = false
      emit('success')
    }

    return {
      $t,
      model,
      update,
      countryList,
      currencyList,
      isLoadingState,
      formFields,
      formValidation,
      onCurrencyChange,
      onCountryChange,
      showSelf,
      additionalFields,
      industryCategoryType,
    }
  },
})
</script>

<style scoped>
.card-footer > .button {
  width: 100%;
}

::v-deep .input-label {
  @apply text-base !important;
}
</style>
