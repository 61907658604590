<template>
  <AppInputSearch
    ref="inputRef"
    v-model="model"
    :label="label"
    :placeholder="placeholder"
    :search-icon="true"
    :blur-items="true"
    :items="searchedCities"
    :loading="loadingCities"
    :analytics-name="analyticsName ? `${analyticsName}-input-city` : ''"
    :disabled="$attrs.disabled"
    :country="country"
    :dismissed="searchDismissed"
    :validation="validation"
    @search="searchCity"
    @select="selectCity"
    @clear="clearValue"
    @focus="onFocus"
    @input="onInput"
    @blur="onBlur"
  />
</template>

<script>
import { usePromiseLazy } from 'vue-composable'
import { computed, ref, reactive, nextTick } from '@vue/composition-api'

import { AppInputSearch, useVModel } from '@oen.web.vue2/ui'

import { useSendMoneyStore, useI18nStore, useEnvStore, useRecipientsStore } from '@galileo/stores'

export default {
  name: 'XeInputCity',
  components: {
    AppInputSearch,
  },
  emits: ['input', 'select'],
  props: {
    value: {
      type: String,
      default: () => {},
    },
    country: {
      type: [String, Object],
      required: true,
    },
    formFields: {
      type: Array,
      default: null,
      required: false,
    },
    onSelect: {
      type: Function,
      default: null,
    },
    analyticsName: {
      type: String,
      default: '',
      required: () => {
        const envStore = useEnvStore()
        return !!envStore.env.VUE_APP_ANALYTICS_ENABLED
      },
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
      required: false,
    },
    validation: {
      type: Object,
      default: null,
      required: false,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const recipientsStore = useRecipientsStore()
    const sendMoneyStore = useSendMoneyStore()

    const inputRef = ref(null)

    const model = ref(props.value)

    const searchedCities = ref(null)

    const searchDismissed = ref(false)

    const searchCityRequest = reactive(
      usePromiseLazy(({ searchValue, deliveryMethod }) =>
        recipientsStore.searchCity({
          city: searchValue,
          country: props.country && props.country.value ? props.country.value : props.country,
          deliveryMethod,
        })
      )
    )

    let gotFocus = false
    const onFocus = () => {
      if (inputRef.value && inputRef.value.isInManual) {
        inputRef.value.isInManual = false
      }

      gotFocus = true
    }

    //based on the search value it will populate the city dropdown
    const searchCity = async (searchValue) => {
      // if focus hasn't been received yet that's the initialization value, so ignore it
      if (!gotFocus || !searchValue) {
        return {}
      }

      let deliveryMethod = sendMoneyStore.form.deliveryMethod

      if (!deliveryMethod) {
        deliveryMethod = 'OfficePickup'
      }

      //get address based on delivery method
      await searchCityRequest.exec({
        searchValue,
        deliveryMethod,
      })

      searchedCities.value = searchCityRequest.result || []

      if (citySelected || searchedCities.value.length < 1) {
        searchedCities.value = []
      }
    }

    let citySelected = false
    let cityValue = null

    //search listener
    const onInput = async (value) => {
      if (!value || (model.value === value && cityValue)) {
        return
      }

      emit('input', value)
    }

    const syncModel = () => {
      if (cityValue) {
        model.value = `${cityValue.cityName}, ${cityValue.stateName}`
      }
      nextTick(() => {
        document.activeElement.blur()
      })
    }

    const selectCity = async (city) => {
      cityValue = city
      //hacky way to trigger the select to close the dialog
      if (inputRef?.value) {
        inputRef.value.isInManual = true
      }
      syncModel()
      emit('select', city)
    }

    const clearValue = () => {
      model.value = ''
      emit('clear', '')
    }

    const onBlur = (e) => {
      // the clear on mobile triggers the onBlur
      // so we check which element triggered the onBlur
      if (e?.relatedTarget) {
        const element = e.relatedTarget
        const attr = element.getAttribute('analytics-name')
        if (attr === 'search-city-input-city-clear-button') {
          clearValue()
          document.activeElement.blur()
        }
      }

      emit('blur')
    }

    return {
      model,
      loadingCities: computed(() => searchCityRequest.loading),
      searchedCities,
      searchCity,
      selectCity,
      clearValue,
      onFocus,
      onBlur,
      onInput,
      $t,
      searchDismissed,
      inputRef,
    }
  },
}
</script>
<style scoped>
::v-deep [analytics-name='search-city-input-city-close-button'] > div {
  float: right;
}
</style>
