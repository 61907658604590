var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppModal',{attrs:{"title":"Recipient’s address","loading":true},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('AppModalHeader',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('EditRecipientAddressModal.ModalTitle').value)+" ")]},proxy:true},{key:"left",fn:function(){return [(!_vm.isMissingInformationFlow)?_c('XeBackButton',{attrs:{"name":_vm.$t('PageSendMoneyAdditionalDetails.GoBackTooltipText').value,"icon":"<","analytics-name":"bank-details-modal-back"},on:{"click":function($event){return _vm.$emit('back')}}}):_vm._e()]},proxy:true},{key:"right",fn:function(){return [_c('XeBackButton',{attrs:{"name":_vm.$t('RecipientDetailsModal.CloseTooltipText').value,"icon":"x","analytics-name":"bank-details-modal-dismiss"},on:{"click":function($event){return _vm.$emit('close')}}})]},proxy:true}])})]},proxy:true},{key:"footer",fn:function(){return [_c('AppModalFooter',[_c('AppButton',{attrs:{"analytics-name":"address-details-modal-update","loading":_vm.isLoadingState,"disabled":(_vm.validation && _vm.validation.$anyInvalid) ||
            _vm.formValidation.$anyInvalid ||
            _vm.isLoadingState ||
            _vm.recipient.deactivated},on:{"click":_vm.update}},[_vm._v(" "+_vm._s(_vm.$t('PageRecipients.SaveButton').value)+" ")])],1)]},proxy:true}]),model:{value:(_vm.showSelf),callback:function ($$v) {_vm.showSelf=$$v},expression:"showSelf"}},[_c('AppSpinnerBig',{key:"1",staticClass:"spinner",attrs:{"loading":_vm.loading}}),_c('Transition',{attrs:{"name":"fade"}},[(!_vm.loading)?_c('DeactivatedRecipientWrapper',{key:"2",attrs:{"recipient":_vm.recipient}},[_c('AppInputCountry',{key:_vm.validation.addressCountry.$value,attrs:{"empty-filter-message":_vm.$t('AddressSearch.noResult').value,"disabled":_vm.recipient.deactivated,"label":_vm.$t('PageRecipients.CountryOfResidence').value,"validation":_vm.validation.addressCountry,"countries":_vm.countryList},on:{"input":function () { return _vm.onCountryChange(_vm.onCountryChangeSpecific); }},model:{value:(_vm.form.addressCountry),callback:function ($$v) {_vm.$set(_vm.form, "addressCountry", $$v)},expression:"form.addressCountry"}}),(!_vm.showEnterManualAddress)?_c('XeAddressSearch',{key:_vm.form.addressLine1,staticClass:"address-search",attrs:{"dropdown-label":_vm.xeAddressSearchLabel,"search-promise-lazy":_vm.searchPromiseLazy,"get-address-details-promise-lazy":_vm.getAddressDetailsPromiseLazy,"display-value":_vm.form.addressLine1,"show-manual-selection":false,"should-output-empty-string":""},on:{"select":_vm.onSelectAddress,"manual":_vm.onManualAddress}}):_vm._e(),(
            _vm.formValidation.addressLine1 &&
            _vm.formValidation.addressLine1.pobox &&
            _vm.formValidation.addressLine1.pobox.$invalid
          )?_c('p',{staticClass:"address-search-pobox-error"},[_vm._v(" "+_vm._s(_vm.formValidation.addressLine1.pobox.$message)+" ")]):(
            _vm.formValidation.addressLine1 &&
            _vm.formValidation.addressLine1.regex &&
            _vm.formValidation.addressLine1.regex.$invalid
          )?_c('p',{staticClass:"address-search-pobox-error"},[_vm._v(" "+_vm._s(_vm.formValidation.addressLine1.regex.$message)+" ")]):_vm._e(),_c('AppDynamicForm',{key:_vm.formFieldsToDisplay.length,attrs:{"fields":_vm.formFieldsToDisplay}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }