export const loading = {
  type: Boolean,
  default: false,
  required: true,
}
export const tag = {
  type: String,
  default: 'span',
}
export const title = {
  type: String,
  default: '',
  required: true,
}
