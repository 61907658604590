<template>
  <div>
    <EditBankDetailsModal
      v-if="selectedRecipient && showEditBankDetailsModal"
      v-model="showEditBankDetailsModal"
      :recipient="recipient"
      is-missing-information-flow
      @close="closeAll"
      @back="closeAll"
      @success="onSuccessEditBankDetails()"
    />

    <EditAddressDetailsModal
      v-if="selectedRecipient && showEditAddressDetailsModal"
      v-model="showEditAddressDetailsModal"
      :recipient="recipient"
      is-missing-information-flow
      @close="closeAll"
      @back="closeAll"
      @success="onSuccessEditAddress()"
    />

    <EditRecipientDetailsModal
      v-if="selectedRecipient && showEditRecipientDetailsModal"
      v-model="showEditRecipientDetailsModal"
      :isConfirmName="nameVerifiedSoloTriggered"
      :recipient="recipient"
      is-missing-information-flow
      @close="closeAll"
      @back="closeAll"
      @success="onSuccessEditRecipient"
      :isSendMoney="isSendMoney"
    />

    <ModifyRecipientModal
      v-if="showModifyRecipientModal"
      v-model="showModifyRecipientModal"
      :myself="recipient.isOwnAccount"
      :business="recipient.isBusinessAccount"
      :recipient="recipient"
      :key="recipient.id"
      :isConfirmName="hasNameNotVerified"
      missing-information
      confirm-text="Edit"
      @success="onSuccessModifyRecipient()"
      @back="closeAll"
    />

    <AddRecipientTypeModal
      v-if="recipient && showEditRecipientTypeModal"
      v-model="showEditRecipientTypeModal"
      @addMyself="addMyself"
      @addSomeoneElse="addSomeoneElse"
      @addBusiness="addBusiness"
    />

    <ConfirmNameSplitModal
      v-if="!recipient.nameVerified && showNameNotVerifiedModal"
      v-model="showNameNotVerifiedModal"
      :recipient="recipient"
      :isSendMoney="isSendMoney"
      @edit="nameSplitEditRecipientName"
      @confirm="$emit('success')"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  onBeforeMount,
  onMounted,
  watch,
} from '@vue/composition-api'

import { useAlert } from '@oen.web.vue2/ui'
import EditBankDetailsModal from '@galileo/components/Views/Recipient/EditBankDetailsModal/EditBankDetailsModal'
import EditAddressDetailsModal from '@galileo/components/Views/Recipient/EditAddressDetailsModal/EditAddressDetailsModal'
import EditRecipientDetailsModal from '@galileo/components/Views/Recipient/EditRecipientDetailsModal'
import ModifyRecipientModal from '@galileo/components/Views/Recipient/ModifyRecipientModal'
import AddRecipientTypeModal from '@galileo/components/Views/Recipient/AddRecipientTypeModal'
import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'
import ConfirmNameSplitModal from '@galileo/components/Views/Recipient/NameSplit/ConfirmNameSplitModal.vue'

import {
  useAnalyticsStore,
  useRecipientsStore,
  useI18nStore,
  useAppStore,
  usePaymentReferenceStore,
  useSendMoneyStore,
  useQuickTransferStore,
} from '@galileo/stores'

import { useRecipientValidation } from '@galileo/composables/useRecipientHelpers'

export default defineComponent({
  emits: ['reset'],
  components: {
    EditBankDetailsModal,
    EditAddressDetailsModal,
    EditRecipientDetailsModal,
    ModifyRecipientModal,
    AddRecipientTypeModal,
    ConfirmNameSplitModal,
  },
  props: {
    recipientValidationErrors: {
      type: Array,
      required: true,
      validator(errors) {
        return errors.every((error) =>
          [
            'BANK_NAME',
            'BANK_ADDRESS_LINE1',
            'BANK_ADDRESS_LINE2',
            'BANK_ADDRESS_LINE3',
            'BANK_BIC',
            'NAT_CLEAR_CODE',
            'IBAN',
            'ACCOUNT_NUM',
            'ADDRESS_LINE1',
            'ADDRESS_LINE2',
            'RECIPIENT_CITY',
            'RECIPIENT_POST_CODE',
            'RECIPIENT_PHONE',
            'RECIPIENT_STATE',
            'ADDRESS_COUNTRY_CODE',
            'IS_BUSINESS',
            'fullNameError',
            'LAST_NAME',
            'FIRST_NAME',
            'nameNotVerified',
          ].includes(error)
        )
      },
    },
    selectedRecipient: {
      type: Object,
      default: () => {},
    },
    isSendMoney: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const recipientsStore = useRecipientsStore()
    const paymentReferenceStore = usePaymentReferenceStore()
    const sendMoneyStore = useSendMoneyStore()

    watch(props.selectedRecipient, (value) => {
      recipient.value = value
      getRecipientDetails()
    })

    onBeforeMount(async () => {
      recipient.value = props.selectedRecipient

      await getRecipientDetails()
    })

    onMounted(() => {
      showMappedModal()
      sendEvent({
        event: SEGMENT_EVENTS.RECIPIENT_INFORMATION_MISSING_STARTED,
        traits: {
          location: props.isSendMoney
            ? SEGMENT_LOCATIONS.SEND_MONEY
            : SEGMENT_LOCATIONS.RECIPIENT_LIST,
        },
      })
    })

    const sendEvent = (segmentEvent) => {
      if (
        !(
          props.recipientValidationErrors.length == 1 &&
          props.recipientValidationErrors.includes('nameNotVerified')
        )
      ) {
        if (!nameVerifiedSoloTriggered.value) {
          analyticsStore.track(segmentEvent)
        }
      }
    }

    const showEditBankDetailsModal = ref(false)
    const showEditAddressDetailsModal = ref(false)
    const showEditRecipientDetailsModal = ref(false)
    const showModifyRecipientModal = ref(false)
    const showEditRecipientTypeModal = ref(false)
    const showNameNotVerifiedModal = ref(false)

    const errorToModal = {
      BANK_NAME: 'bankDetails',
      BANK_ADDRESS_LINE1: 'bankDetails',
      BANK_ADDRESS_LINE2: 'bankDetails',
      BANK_BIC: 'bankDetails',
      NAT_CLEAR_CODE: 'bankDetails',
      IBAN: 'bankDetails',
      ACCOUNT_NUM: 'bankDetails',
      ADDRESS_LINE1: 'addressDetails',
      ADDRESS_LINE2: 'addressDetails',
      RECIPIENT_CITY: 'addressDetails',
      RECIPIENT_POST_CODE: 'addressDetails',
      RECIPIENT_PHONE: 'addressDetails',
      RECIPIENT_STATE: 'addressDetails',
      ADDRESS_COUNTRY_CODE: 'addressDetails',
      IS_BUSINESS: 'accountTypeDetails',
      fullNameError: 'recipientDetails',
      LAST_NAME: 'recipientDetails',
      FIRST_NAME: 'recipientDetails',
      nameNotVerified: 'nameNotVerified',
    }

    const modals = {
      accountTypeDetails: {
        wasShown: false,
        toggler: showEditRecipientTypeModal,
      },
      recipientDetails: {
        wasShown: false,
        toggler: showEditRecipientDetailsModal,
      },
      bankDetails: {
        wasShown: false,
        toggler: showEditBankDetailsModal,
      },
      addressDetails: {
        wasShown: false,
        toggler: showEditAddressDetailsModal,
      },
      multipleDetails: {
        wasShown: false,
        toggler: showModifyRecipientModal,
      },
      nameNotVerified: {
        wasShown: false,
        toggler: showNameNotVerifiedModal,
      },
    }

    const hasNameNotVerified = ref(false)

    const modalsToShow = computed(() => {
      const list = props.recipientValidationErrors.map((error) => errorToModal[error])
      const set = new Set(list)

      //NB set can have undefined value that means we didn't catch the exact error
      //in that case we display the generic ModifyRecipientModal that will loop through all the screens

      if (set.has('nameNotVerified')) {
        hasNameNotVerified.value = true
      }

      // Temporary hack to show ModifyRecipientModal instead of cycling through
      // separate modals, until we implement them differently
      if (set.size > 1) {
        //if there are more than 2 errors or has 1 undefined error and it misses the account type we show the generic ModifyRecipientModal and also the accountType
        if (set.has('accountTypeDetails') && (set.size >= 2 || set.has(undefined))) {
          return new Set(['accountTypeDetails', 'multipleDetails'])
        }

        //if there is any recipient details missing and also name has to be verified it will show only one screen not all of them.
        if (set.size == 2 && hasNameNotVerified.value && set.has('recipientDetails')) {
          return new Set(['recipientDetails'])
        }
        return new Set(['multipleDetails'])
      } else {
        // if we have just one error and it's undefined we go for generic ModifyRecipientModal as we didn't catcvh the exact error
        if (props.recipientValidationErrors.length > 0 && set.has(undefined)) {
          return new Set(['multipleDetails'])
        }
        if (set.has('accountTypeDetails')) {
          return new Set(['accountTypeDetails', 'multipleDetails'])
        }

        return set
      }
    })

    const showMappedModal = () => {
      for (const [key, value] of Object.entries(modals)) {
        if (modalsToShow.value.has(key) && !value.wasShown) {
          value.toggler.value = true
          break
        }
      }

      // If all modals were shown, reload the component and go
      // to the next step in Send Money Flow
      if ([...modalsToShow.value].every((modal) => modals[modal].wasShown)) {
        sendEvent({
          event: SEGMENT_EVENTS.RECIPIENT_INFORMATION_MISSING_COMPLETED,
        })
        emit('reset')
        emit('success', recipient.value)
      }
    }
    const { showEditMissingRecipientInformation } = useRecipientValidation()

    const closeAll = (shouldReset = true) => {
      showEditBankDetailsModal.value = false
      showEditAddressDetailsModal.value = false
      showEditRecipientDetailsModal.value = false
      showModifyRecipientModal.value = false
      showNameNotVerifiedModal.value = false

      //Workaround for scrolling
      //TODO: Implement recipients info through routes
      try {
        const bodyElement = document.getElementsByTagName('body')[0]

        if (bodyElement.classList.contains('overflow-hidden')) {
          bodyElement.classList.remove('overflow-hidden')
        }
      } catch (ex) {
        useAppStore().logInfo('Failed to remove overflow')
      }

      if (shouldReset) {
        emit('reset', true)
      }
    }

    const onSuccessAlertAndReloadRecipient = async () => {
      const { add } = useAlert()
      add(`${$t('RecipientDetailsModal.SaveSuccessfulAlertText').value}`)
      try {
        // await getRecipientDetails()
        emit('reload-recipient')
        closeAll(false)
      } catch (ex) {
        useAppStore().logInfo('Exception reloading recipient ', ex)
      }
    }

    const nameVerifiedSoloTriggered = ref(false)

    const nameSplitEditRecipientName = () => {
      props.recipientValidationErrors.pop()
      modals.nameNotVerified.wasShown = true
      nameVerifiedSoloTriggered.value = true
      props.recipientValidationErrors.push('fullNameError')
      showMappedModal()
    }

    const onSuccessEditBankDetails = async () => {
      modals.bankDetails.wasShown = true
      await onSuccessAlertAndReloadRecipient()
      showMappedModal()
    }

    const onSuccessEditRecipient = async () => {
      modals.recipientDetails.wasShown = true
      await onSuccessAlertAndReloadRecipient()
      showMappedModal()
    }

    const onSuccessEditAddress = async () => {
      modals.addressDetails.wasShown = true
      await onSuccessAlertAndReloadRecipient()
      showMappedModal()
    }

    const onSuccessModifyRecipient = async () => {
      modals.multipleDetails.wasShown = true
      await onSuccessAlertAndReloadRecipient()

      paymentReferenceStore.refreshPaymentReferenceForm()

      paymentReferenceStore.setIsReasonForTransferLocked(true)

      showMappedModal()
    }

    const recipient = ref(null)
    const isCashRecipient = ref(props.selectedRecipient.deliveryMethod === 'CashPayout')

    const getRecipientDetails = async () => {
      let recipientId = props.selectedRecipient.id

      if (!recipientId && recipient) {
        recipientId = recipient.value.id
      }

      if (!isCashRecipient.value) {
        recipient.value = await recipientsStore.getRecipient(recipientId)
      } else {
        recipient.value = await recipientsStore.getCashRecipient(recipientId)
      }
    }

    const updateAccountType = ({ isBusiness, isOwnAccount }) => {
      recipient.value.isBusinessAccount = isBusiness
      recipient.value.isOwnAccount = isOwnAccount
      showModifyRecipientModal.value = true
      modals.accountTypeDetails.wasShown = true
      modals.accountTypeDetails.toggler.value = false
      showMappedModal()
    }

    const addMyself = async () => {
      updateAccountType({ isBusiness: false, isOwnAccount: true })
    }

    const addSomeoneElse = async () => {
      updateAccountType({ isBusiness: false, isOwnAccount: false })
    }

    const addBusiness = async () => {
      updateAccountType({ isBusiness: true, isOwnAccount: false })
    }

    return {
      $t,
      showNameNotVerifiedModal,
      showEditBankDetailsModal,
      showEditAddressDetailsModal,
      showEditRecipientDetailsModal,
      showModifyRecipientModal,
      modalsToShow,
      closeAll,
      onSuccessEditBankDetails,
      onSuccessEditRecipient,
      onSuccessEditAddress,
      onSuccessModifyRecipient,
      showEditRecipientTypeModal,
      addMyself,
      addSomeoneElse,
      addBusiness,
      recipient,
      nameSplitEditRecipientName,
      nameVerifiedSoloTriggered,
      hasNameNotVerified,
    }
  },
})
</script>
