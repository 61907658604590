<template>
  <AppModal v-model="model" :title="$t('ComponentAddRecipientDeliveryTypeModal.Header').value">
    <template #header="{ dismiss }">
      <AppModalHeader>
        <template #right>
          <XeBackButton analytics-name="add-recipient-delivery-type-close" @click="dismiss" />
        </template>
        <h1>{{ $t('ComponentAddRecipientDeliveryTypeModal.Header').value }}</h1>
      </AppModalHeader>
    </template>

    <AppList>
      <XeListItem
        analytics-name="add-recipient-delivery-type-bank"
        :text="$t('ComponentAddRecipientDeliveryTypeModal.ButtonBank').value"
        :subTitle="$t('ComponentAddRecipientDeliveryTypeModal.ButtonBankDescription').value"
        :select="() => $emit('addBank')"
      >
        <template #figure-icon>
          <AppIcon name=" ">
            <IconFinance />
          </AppIcon>
        </template>
      </XeListItem>

      <XeListItem
        icon="IconMyself"
        analytics-name="add-recipient-delivery-type-cashpayout"
        :text="$t('ComponentAddRecipientDeliveryTypeModal.ButtonCashPayout').value"
        :subTitle="$t('ComponentAddRecipientDeliveryTypeModal.ButtonCashPayoutDescription').value"
        :select="() => $emit('addCashPayout')"
      >
        <template #figure-icon>
          <AppIcon name=" ">
            <IconMoney />
          </AppIcon>
        </template>
      </XeListItem>

      <XeListItem
        icon="IconMyself"
        analytics-name="add-recipient-delivery-type-mobile-wallet"
        :text="$t('ComponentAddRecipientDeliveryTypeModal.ButtonMobileWallet').value"
        :subTitle="$t('ComponentAddRecipientDeliveryTypeModal.ButtonMobileWalletDescription').value"
        :select="() => $emit('addMobileWalletRecipient')"
      >
        <template #figure-icon>
          <AppIcon name=" ">
            <IconWallet />
          </AppIcon>
        </template>
      </XeListItem>
    </AppList>
  </AppModal>
</template>

<script>
import { useI18nStore } from '@galileo/stores'

import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'
import RiaListItem from '@galileo/components/RiaListItem/RiaListItem'
import XeListItem from '@galileo/components/XeListItem/XeListItem'

import { IconFinance, IconMoney, IconWallet } from '@oen.web.vue2/icons'
import { useVModel, AppModal, AppModalHeader, AppList, AppIcon } from '@oen.web.vue2/ui'

export default {
  name: 'AddRecipientDeliveryTypeModal',
  emits: ['input', 'addBank', 'addCashPayout'],
  components: {
    AppModal,
    AppModalHeader,
    IconFinance,
    IconMoney,
    AppList,
    XeBackButton,
    RiaListItem,
    XeListItem,
    AppIcon,
    IconWallet,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)

    return {
      model,
      $t,
    }
  },
}
</script>

<style scoped>
.card-header {
  ::v-deep .card-header-title {
    @apply text-lg leading-6 h-auto;
  }
  @screen sm {
    @apply text-lg leading-6 h-auto;
    @apply px-12 py-12;
  }
}

.card-header-right {
  @apply flex-1;

  @screen sm {
    @apply flex-1;
  }
}

::v-deep .card-content {
  @apply px-4;

  @screen sm {
    @apply px-12;
  }
}
</style>
