<template>
  <div>
    <!-- Modify recipient modal -->
    <AppModal
      v-model="model"
      class="recipient-modal"
      :title="$t('ComponentModifyRecipientModal.TitleModal').value"
      @show="onShow"
    >
      <template #header="{ dismiss }">
        <AppModalHeader v-if="!recipient">
          <template #default> {{ $t('ComponentModifyRecipientModal.AddHeader').value }}</template>

          <template #left>
            <XeBackButton
              theme="icon"
              icon="<"
              icon-style="outline"
              type="button"
              :loading="false"
              default-slot="Button"
              analytics-name="modify-cash-recipient-modal-back-add"
              @click="backClick"
            />
          </template>
          <template #right>
            <XeBackButton
              theme="icon"
              type="button"
              icon="x"
              icon-style="outline"
              :loading="false"
              default-slot="Button"
              analytics-name="modify-recipient-modal-hide-add"
              @click="dismiss"
            />
          </template>
        </AppModalHeader>

        <AppModalHeader v-if="recipient">
          <template #default>{{ $t('ComponentModifyRecipientModal.EditHeader').value }}</template>
          <template #left>
            <AppBackButton
              icon="<"
              analytics-name="modify-recipient-modal-hide-edit"
              @click="dismiss"
            />
          </template>
          <template #right>
            <AppButton
              theme="icon"
              analytics-name="modify-recipient-modal-delete"
              @click="$emit('delete', recipient)"
            >
              <AppIcon name=" ">
                <IconTrashCan />
              </AppIcon>
            </AppButton>
          </template>
        </AppModalHeader>
      </template>

      <AppAlert class="mb-6" theme="gray">
        {{ $t('ModifyRecipientModal.FraudNotice').value }}
        <AppAlertAction @click="onLearnMore">
          {{ $t('ModifyRecipientModal.FraudLearnMore').value }}
        </AppAlertAction>
      </AppAlert>

      <AppAlert v-if="recipient" class="mb-6" theme="gray">
        {{ $t('ComponentModifyRecipientModal.NoteModify').value }}
      </AppAlert>

      <AppSpinnerBig :loading="loading" inline />

      <AppDynamicForm :fields="formFields"></AppDynamicForm>

      <XeInputCity
        :value="form.recipientStateAndCity"
        :country="recipientCountry"
        :label="$t('ComponentEditRecipientDetailsModal.CityStateLabel').value"
        :placeholder="$t('ComponentEditRecipientDetailsModal.CityStatePlaceholder').value"
        analytics-name="search-city"
        :disabled="!recipientCountry"
        :validation="validation.recipientStateAndCity"
        @select="setState"
        @input="input"
        @clear="clear"
      />

      <template #footer>
        <AppModalFooter v-if="hasFormFields">
          <AppButton
            analytics-name="modify-recipient-modal-submit"
            :loading="submitting"
            :disabled="isFormInvalid || validation.$anyInvalid || !validCitySelected"
            @click="submit"
          >
            {{ $t('ComponentModifyRecipientModal.ButtonSave').value }}
          </AppButton>
        </AppModalFooter>
      </template>
    </AppModal>

    <StopFraudWarningModal :show-fraud-info="showFraudInfo" @onFraudGotIt="onFraudGotIt()" />
  </div>
</template>

<script>
import { ref, toRef, computed, reactive } from '@vue/composition-api'
import { useValidation } from 'vue-composable'

import getValueRequiredValidation from '@galileo/utilities/validations.utility'

import { useCashRecipientForm } from '@galileo/forms/CashRecipientForm'
import { getSepaCompliantValue } from '@galileo/composables/useRecipientHelpers/useRecipientUpdate'

import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'

import XeInputCity from '@galileo/components/XeInputCity/XeInputCity'

import { IconTrashCan } from '@oen.web.vue2/icons'

import StopFraudWarningModal from '@galileo/components/Views/Recipient/StopFraudWarningModal/StopFraudWarningModal'

import {
  useVModel,
  AppModal,
  AppModalFooter,
  AppButton,
  AppIcon,
  AppDynamicForm,
  AppSpinnerBig,
  AppAlert,
  AppBackButton,
  AppModalHeader,
  AppAlertAction,
} from '@oen.web.vue2/ui'

import {
  useI18nStore,
  useAppStore,
  useRecipientsStore,
  useSendMoneyStore,
  useAuthStore,
} from '@galileo/stores'

export default {
  name: 'ModifyCashRecipientModal',
  emits: ['input', 'submit', 'delete', 'back'],
  components: {
    AppModal,
    AppModalFooter,
    AppButton,
    AppIcon,
    IconTrashCan,
    AppDynamicForm,
    AppSpinnerBig,
    AppAlert,
    AppBackButton,
    XeBackButton,
    AppModalHeader,
    AppAlertAction,
    StopFraudWarningModal,
    XeInputCity,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    recipient: {
      type: Object,
      required: false,
      default: null,
    },
    myself: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSendMoney: {
      type: Boolean,
      required: false,
      default: false,
    },
    confirmText: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const authStore = useAuthStore()
    const appStore = useAppStore()
    const recipientsStore = useRecipientsStore()
    const sendMoneyStore = useSendMoneyStore()
    const valueRequired = getValueRequiredValidation()

    const { setup, formFields, hasFormFields, isFormInvalid } = useCashRecipientForm()

    const loading = ref(true)
    const submitting = ref(false)

    const { model } = useVModel(props, emit)

    const recipients = recipientsStore.recipients
    const hasRecipients = computed(() => {
      return recipients && recipients.length > 0
    })

    //////////////////////////////////////////////////////////////////////////////////////

    const recipientCountry = computed(() => {
      const countryField = formFields.value.find((field) => field.id === 'CountryCode')

      return countryField?.value ?? ''
    })

    const addressCityField = computed(() => {
      return formFields.value.find((field) => field.id === 'AddressCity')
    })

    const addressStateField = computed(() => {
      return formFields.value.find((field) => field.id === 'AddressState')
    })

    const form = reactive({
      recipientStateAndCity: '',
    })

    const validation = useValidation({
      recipientStateAndCity: {
        $value: toRef(form, 'recipientStateAndCity'),
        valueRequired,
        validStateCity: {
          $validator(v) {
            if (!v) {
              return true
            }
            const cityStateValidationRegex = new RegExp(/^[a-zA-Z0-9\s\(\)\+\-'/\?\.:,]{1,50}$/)
            return cityStateValidationRegex.test(v)
          },
          $message: $t('ComponentModifyRecipientModal.EnterValidCityMessage').value,
        },
      },
    })

    let recipientCity = null
    let validCitySelected = ref(false)

    const setState = (city) => {
      form.recipientStateAndCity = `${city.cityName}, ${city.stateName}`
      addressCityField.value.value = city.cityName
      addressStateField.value.value = city.stateName
      recipientCity = `${city.cityName}, ${city.stateName}`
      validCitySelected.value = true
    }

    const input = (value) => {
      form.recipientStateAndCity = value
      if (recipientCity !== value) {
        validCitySelected.value = false
      }
    }

    const clear = () => {
      form.recipientStateAndCity = ''
      addressCityField.value.value = ''
      addressStateField.value.value = ''
      validCitySelected.value = false
    }

    //////////////////////////////////////////////////////////////////////////////////////

    const onShow = () => {
      let fieldsPromise
      if (props.recipient) {
        fieldsPromise = recipientsStore.getCashFields(props.recipient.id)
      } else {
        fieldsPromise = recipientsStore.getCashFields('')
      }

      fieldsPromise.then((loadedFields) => {
        loading.value = false

        if (loadedFields) {
          let defaultValues = {}
          if (props.recipient) {
            defaultValues = props.recipient
          } else {
            // Recipient's country should be set and locked only for SMF
            if (props.isSendMoney) {
              defaultValues['CountryCode'] = sendMoneyStore.getCountryTo
              defaultValues['CurrencyCode'] = sendMoneyStore.form.currencyTo
            }

            if (props.myself) {
              const userProfile = authStore.userProfile
              defaultValues['FirstName'] = userProfile.firstName
              defaultValues['MiddleName'] = userProfile.middleName
              defaultValues['LastNames'] = userProfile.lastName
            }
          }
          setup(loadedFields, defaultValues)
        }
      })
    }

    const submit = () => {
      if (props.recipient) {
        emit('submit', formFields.value)
      } else {
        addCashRecipientSubmit(formFields.value)
      }
    }

    const deliveryMethod = sendMoneyStore.form.deliveryMethod

    const addCashRecipientSubmit = async (fields) => {
      submitting.value = true

      try {
        fields.forEach((field) => {
          if (['FirstName', 'MiddleName', 'LastNames'].includes(field.id)) {
            field.value = getSepaCompliantValue(field.value)
          }
        })

        const newRecipient = await recipientsStore.addCashRecipient({
          deliveryMethod: deliveryMethod,
          fields: fields,
        })
        emit('success', newRecipient)
      } catch (ex) {
        appStore.logException('Exception during adding recipient in recipients', ex)
        appStore.messageBoxGenericError()
      } finally {
        submitting.value = false
      }
    }

    const backClick = () => {
      emit('back')
    }

    let showFraudInfo = ref(false)

    const onLearnMore = () => {
      showFraudInfo.value = true
    }

    const onFraudGotIt = (value) => {
      showFraudInfo.value = value
    }

    return {
      model,
      submit,
      formFields,
      hasFormFields,
      isFormInvalid,
      loading,
      submitting,
      onShow,
      backClick,
      hasRecipients,
      showFraudInfo,
      onLearnMore,
      onFraudGotIt,
      form,
      recipientCountry,
      setState,
      clear,
      $t,
      validation,
      validCitySelected,
      input,
    }
  },
}
</script>

<style scoped>
.alert {
  @apply border-none;
  @apply text-gray;
}

::v-deep .card-header {
  @apply px-12;
}

::v-deep .card-content .card-content-block {
  @apply mb-6;
}

.card-footer .button {
  @apply w-full;

  &.button--primary:disabled {
    @apply bg-blue-button-disabled text-white;
  }
}

::v-deep .prime-dropdown .p-dropdown-panel .p-dropdown-item .dropdown-option {
  @apply py-2.5 px-4;
  @apply font-medium leading-5;
}

::v-deep .p-dropdown-items-wrapper {
  @apply my-2;
  @apply max-w-sm;
  @apply border-none shadow-xe-1;
  border-radius: 8px;
}
</style>
