<template>
  <LayoutDashboardPage title="">
    <!--Loading-->
    <AppSpinnerBig :loading="loading" />

    <!--No Recipients-->
    <NoRecipients
      v-if="!hasRecipients && !loading"
      :is-send-money="false"
      @addRecipient="addRecipientHandler"
      class="no-recipients-card"
    />

    <AppCard
      class="recipients-app-card"
      :class="{ 'recipients-app-card-visible': hasRecipients && !loading }"
      :ignoreFigureTitle="true"
    >
      <!--Recipients-->
      <div class="title-box">
        <AppCardFigureTitle
          class="recipients-title-text"
          :title="hasRecipients && !loading ? $t('PageRecipients.Title').value : ''"
        />

        <AppButton
          v-if="!loading && hasRecipients"
          class="xe-add-button"
          theme="secondary"
          analytics-name="recipients-add"
          @click="addRecipientHandler"
        >
          <template #leftIcon>
            <AppIcon name=" ">
              <IconAdd />
            </AppIcon>
          </template>
          {{ $t('ComponentNoRecipients.ButtonAdd').value }}
        </AppButton>
      </div>

      <XeAddListItem
        class="xe-list-item-button"
        :select="addNewRecipient"
        :text="$t('AddRecipient.AddRecipientText').value"
      />
      <RiaRecipients
        v-show="!loading && hasRecipients"
        :show-add-recipient="false"
        :show-menu="true"
        :show-loader="false"
        @viewRecipient="viewRecipient($event)"
        @editRecipient="editRecipient($event, false)"
        @deleteRecipient="deleteRecipient($event)"
        @sendMoneyRecipient="startSendMoney($event)"
        @add="addRecipient.addRecipient()"
        @loaded="loadedRecipients"
        class="recipients-list"
      />
    </AppCard>
    <!-- keep it so that the margin bottom works properly -->
    <div class="margin-spacer">-</div>

    <!-- View Recipient Modal -->
    <RecipientDetailsModal
      v-if="targetRecipient && showViewRecipient"
      v-model="showViewRecipient"
      :selected-recipient="targetRecipient"
      :confirm-text="$t('PageRecipients.ButtonSendMoney').value"
      @onHidden="hiddenViewRecipient()"
      @edit="editRecipient(targetRecipient, true)"
      @confirm="startSendMoney"
      @delete="deleteRecipient"
    />

    <!-- Edit Recipient Modal -->
    <ModifyRecipientModal
      v-if="targetRecipient && showEditRecipient"
      :value="showEditRecipient"
      :v-model="showEditRecipient"
      :recipient="targetRecipient"
      :business="targetRecipient.isBusinessAccount"
      @success="onSuccessfulEditRecipient"
      @delete="deleteRecipient"
      @back="hideEditRecipient(false)"
    />

    <!-- Delete Recipient dialog -->
    <DeleteRecipientDialog
      v-if="targetRecipient"
      :value="showDeleteRecipient"
      :recipient="targetRecipient"
      @success="onSuccessfulDelete"
      @cancel="cancelDeleteRecipient()"
    />

    <!-- Add Recipient Delivery Type Modal -->
    <AddRecipientDeliveryTypeModal
      v-model="addRecipient.showSelectDeliveryType"
      @addBank="addRecipient.addBank()"
      @addCashPayout="addRecipient.addCashPayout()"
      @addMobileWalletRecipient="addRecipient.addMobileWalletRecipient()"
    />

    <!-- Add Recipient Type Modal -->
    <AddRecipientTypeModal
      v-model="addRecipient.showSelectType"
      @addMyself="addRecipient.addMyBankAccount()"
      @addSomeoneElse="addRecipient.addSomeoneElse()"
      @addBusiness="addRecipient.addBusiness()"
    />

    <!-- Add Recipient Modal -->
    <ModifyRecipientModal
      v-if="addRecipient.showAddRecipient"
      v-model="addRecipient.showAddRecipient"
      :myself="addRecipient.type.myself"
      :business="addRecipient.type.business"
      :confirm-text="$t('PageRecipients.ButtonCreate').value"
      @success="onSuccessfulAddRecipient"
      @back="(isClose) => addRecipient.backFromAddRecipient(isClose)"
    />

    <!-- Add Cash Recipient Modal -->
    <ModifyCashRecipientModal
      v-if="addRecipient.showAddCashRecipient"
      v-model="addRecipient.showAddCashRecipient"
      :value="addRecipient.showAddCashRecipient"
      :confirm-text="$t('PageRecipients.ButtonCreate').value"
      @success="onSuccessfulCashAddRecipient"
      @submit="addCashRecipientSubmit($event)"
      @back="addRecipient.backFromAddCashRecipient()"
    />
  </LayoutDashboardPage>
</template>

<script>
import { ref, computed, reactive, onBeforeMount } from '@vue/composition-api'
import RiaRecipients from '@galileo/components/RiaRecipients/RiaRecipients'
import NoRecipients from '@galileo/components/Views/Recipient/NoRecipients'
import RecipientDetailsModal from '@galileo/components/Views/Recipient/RecipientDetailsModal'
import DeleteRecipientDialog from '@galileo/components/Views/Recipient/DeleteRecipientDialog'
import ModifyRecipientModal from '@galileo/components/Views/Recipient/ModifyRecipientModal'
import ModifyCashRecipientModal from '@galileo/components/Views/Recipient/ModifyCashRecipientModal'
import AddRecipientDeliveryTypeModal from '@galileo/components/Views/Recipient/AddRecipientDeliveryTypeModal'
import LayoutDashboardPage from '@galileo/components/LayoutDashboardPage/LayoutDashboardPage'
import AddRecipientTypeModal from '@galileo/components/Views/Recipient/AddRecipientTypeModal'
import { IconAdd } from '@oen.web.vue2/icons'
import {
  SEGMENT_EVENTS,
  SEGMENT_LOCATIONS,
  SEGMENT_PAYOUT_METHODS,
} from '@galileo/constants/segmentAnalytics'
import XeAddListItem from '@galileo/components/XeAddListItem/XeAddListItem'

import { AppSpinnerBig, AppButton, AppIcon, AppCard, AppCardFigureTitle } from '@oen.web.vue2/ui'
import { storeToRefs } from 'pinia'

import {
  useI18nStore,
  useRecipientsStore,
  useAnalyticsStore,
  useSendMoneyStore,
  useAuthStore,
  useThemeStore,
} from '@galileo/stores'

export default {
  name: 'Recipients',
  components: {
    AppCard,
    XeAddListItem,
    AppCardFigureTitle,
    AppSpinnerBig,
    AppButton,
    AppIcon,
    RiaRecipients,
    NoRecipients,
    RecipientDetailsModal,
    DeleteRecipientDialog,
    ModifyRecipientModal,
    ModifyCashRecipientModal,
    LayoutDashboardPage,
    AddRecipientDeliveryTypeModal,
    AddRecipientTypeModal,
    IconAdd,
  },
  setup() {
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const recipientsStore = useRecipientsStore()
    const authStore = useAuthStore()
    const sendMoneyStore = useSendMoneyStore()
    const loading = ref(true)
    const newRecipientId = ref('')

    analyticsStore.track({
      event: SEGMENT_EVENTS.RECIPIENT_ACCESSED,
      traits: {
        location: SEGMENT_LOCATIONS.RECIPIENT_LIST,
      },
    })

    const hasRecipients = computed(() => {
      const recipients = recipientsStore.recipients
      if (recipients) {
        let recipientsNotGlobal = recipients.filter(
          (recipient) => recipient?.rawData && !recipient.rawData?.isGlobal
        )

        let length = recipientsNotGlobal.length
        recipientsNotGlobal.forEach((recipient) => {
          if (!recipient) {
            length--
          }
        })

        return length > 0
      }
      return false
    })

    const showViewRecipient = ref(false)
    const targetRecipient = ref({})
    let editingRecipientFromView = false

    const viewRecipient = (recipient) => {
      targetRecipient.value = recipient
      showViewRecipient.value = true
    }

    const startSendMoney = async (recipient) => {
      if (recipient) {
        targetRecipient.value = recipient
      }
      sendMoneyStore.restoreDefaultForm()
      sendMoneyStore.setAvailableCurrencies(targetRecipient.value.country)
      sendMoneyStore.form.countryTo = targetRecipient.value.country
      sendMoneyStore.form.recipient = targetRecipient.value

      await sendMoneyStore.openSendMoney()
    }

    const hiddenViewRecipient = () => {
      if (!editingRecipientFromView) {
        unselectRecipient()
      }
    }

    const unselectRecipient = () => {
      targetRecipient.value = {}
    }
    const showEditRecipient = ref(false)

    const editRecipient = async (recipient, fromViewRecipient) => {
      if (fromViewRecipient) {
        editingRecipientFromView = true
      }
      showViewRecipient.value = false
      showEditRecipient.value = true
      targetRecipient.value = recipient
    }

    const onSuccessfulEditRecipient = (updatedRecipient) => {
      targetRecipient.value = updatedRecipient
      hideEditRecipient(true)
    }

    const hideEditRecipient = (viewRecipient) => {
      showEditRecipient.value = false
      if (viewRecipient || editingRecipientFromView) {
        showViewRecipient.value = true
        editingRecipientFromView = false
      }
    }

    // Delete Recipient Dialog
    const showDeleteRecipient = ref(false)

    const deleteRecipient = (recipient) => {
      if (!targetRecipient.value.id) {
        targetRecipient.value = recipient
      }

      showDeleteRecipient.value = true
    }

    const onSuccessfulDelete = () => {
      if (showViewRecipient.value) {
        showViewRecipient.value = false
      }
      targetRecipient.value = {}
      //editingRecipient = false
      editingRecipientFromView = false
      showDeleteRecipient.value = false
      showEditRecipient.value = false
    }

    const cancelDeleteRecipient = () => {
      if (!editingRecipientFromView) {
        unselectRecipient()
      }
      showDeleteRecipient.value = false
    }
    // Add Recipient Modal
    const addNewRecipient = () => {
      addRecipientHandler()
      analyticsStore.track({
        event: SEGMENT_EVENTS.RECIPIENT_STARTED,
        traits: {
          location: SEGMENT_LOCATIONS.RECIPIENT_LIST,
        },
      })
    }
    const addRecipient = reactive({
      showSelectDeliveryType: false,
      showSelectType: false,
      showAddRecipient: false,
      showAddCashRecipient: false,
      addRecipient() {
        this.showSelectDeliveryType = true
      },
      type: {
        myself: false,
        business: false,
      },
      addBank() {
        this.showSelectDeliveryType = false
        this.showSelectType = true
        analyticsStore.track({
          event: SEGMENT_EVENTS.RECIPIENT_TYPE_SELECTED,
          traits: {
            recipientPayoutMethod: 'Bank',
            location: SEGMENT_LOCATIONS.RECIPIENT_LIST,
          },
        })
        analyticsStore.track({
          event: SEGMENT_EVENTS.RECIPIENT_STARTED,
          traits: {
            location: SEGMENT_LOCATIONS.RECIPIENT_LIST,
            recipientPaymentMethod: 'Bank',
          },
        })
      },
      addCashPayout() {
        sendMoneyStore.setDeliveryMethodAction('CashPayout')
        this.resetType()
        this.showSelectDeliveryType = false
        this.showAddCashRecipient = true
        analyticsStore.track({
          event: SEGMENT_EVENTS.RECIPIENT_TYPE_SELECTED,
          traits: {
            recipientPayoutMethod: 'Cash',
            location: SEGMENT_LOCATIONS.RECIPIENT_LIST,
          },
        })
        analyticsStore.track({
          event: SEGMENT_EVENTS.RECIPIENT_STARTED,
          traits: {
            location: SEGMENT_LOCATIONS.RECIPIENT_LIST,
            recipientPaymentMethod: 'Cash',
          },
        })
      },
      addMobileWalletRecipient() {
        sendMoneyStore.setDeliveryMethodAction('MobileWallet')
        this.resetType()
        this.showSelectDeliveryType = false
        this.showAddCashRecipient = true
        analyticsStore.track({
          event: SEGMENT_EVENTS.RECIPIENT_TYPE_SELECTED,
          traits: {
            recipientPayoutMethod: SEGMENT_PAYOUT_METHODS.MOBILE_PAYMENT,
            location: SEGMENT_LOCATIONS.RECIPIENT_LIST,
          },
        })
        analyticsStore.track({
          event: SEGMENT_EVENTS.RECIPIENT_STARTED,
          traits: {
            location: SEGMENT_LOCATIONS.RECIPIENT_LIST,
            recipientPaymentMethod: SEGMENT_PAYOUT_METHODS.MOBILE_PAYMENT,
          },
        })
      },
      backFromAddRecipient(isClose = false) {
        this.showAddRecipient = false
        this.showSelectType = !isClose
      },
      backFromAddCashRecipient() {
        this.showAddCashRecipient = false
        this.showSelectDeliveryType = true
      },
      addMyBankAccount() {
        if (isCorporateAccount.value) {
          this.addBusiness()
          this.type.myself = true
          this.type.business = true
        } else {
          this.addMyself()
        }
      },
      addMyself() {
        this.resetType()
        this.type.myself = true
        this.showAddRecipient = true
      },
      addSomeoneElse() {
        this.resetType()
        this.showAddRecipient = true
      },
      addBusiness() {
        this.resetType()
        this.type.business = true
        this.showAddRecipient = true
      },
      //helper
      resetType() {
        this.showSelectType = false
        this.type.myself = false
        this.type.business = false
      },
    })

    const onSuccessfulAddRecipient = (newRecipientResponse) => {
      addRecipient.showAddRecipient = false
      targetRecipient.value = newRecipientResponse
      showViewRecipient.value = true
      newRecipientId.value = newRecipientResponse.id
    }

    const onSuccessfulCashAddRecipient = (newRecipientResponse) => {
      addRecipient.showAddCashRecipient = false
      targetRecipient.value = newRecipientResponse
      showViewRecipient.value = true
      newRecipientId.value = newRecipientResponse.id
    }

    const loadedRecipients = () => {
      loading.value = false
    }

    const { isCorporateAccount } = storeToRefs(authStore)
    const isBranded = useThemeStore().isBranded

    const addRecipientHandler = () =>
      isCorporateAccount.value || isBranded ? addRecipient.addBank() : addRecipient.addRecipient()

    onBeforeMount(() => {
      const recipientToDisplay = recipientsStore.selectedRecipient
      const shouldDisplayNewRecipient = recipientsStore.showAccountAddRecipient

      if (recipientToDisplay.id || shouldDisplayNewRecipient) {
        loading.value = false
        if (recipientToDisplay.id) {
          targetRecipient.value = recipientToDisplay
          showViewRecipient.value = true
          recipientsStore.setSelectedRecipient(null)
        } else if (shouldDisplayNewRecipient) {
          addRecipientHandler()
          recipientsStore.showAccountAddRecipient = false
        }
      }
    })

    return {
      loading,
      hasRecipients,
      viewRecipient,
      startSendMoney,
      addRecipient,
      onSuccessfulAddRecipient,
      onSuccessfulCashAddRecipient,
      loadedRecipients,
      showViewRecipient,
      targetRecipient,
      hiddenViewRecipient,
      showEditRecipient,
      hideEditRecipient,
      editRecipient,
      onSuccessfulEditRecipient,
      showDeleteRecipient,
      deleteRecipient,
      cancelDeleteRecipient,
      onSuccessfulDelete,
      $t,
      addNewRecipient,
      isCorporateAccount,
      addRecipientHandler,
    }
  },
}
</script>

<style scoped>
::v-deep .title-box {
  @apply flex items-start justify-between mb-12;
  .card-figure-title-title {
    @apply text-2xl;
    font-weight: var(--theme-card-header-title-font-weight, theme('fontWeight.medium'));
  }

  .button {
    @apply w-auto;
    .button-inner {
      white-space: nowrap;
    }
  }
}

.xe-list-item-button {
  @apply items-center w-full mt-0;

  @screen md {
    @apply hidden;
  }
}

.recipients-app-card {
  display: none;
  @apply mt-0 mb-0 mx-auto;
  @apply max-w-3xl;
  @apply h-auto !important;
}

.recipients-app-card-visible {
  display: flex;
}

.recipients-list {
  @apply mt-4;
}

.recipient-add {
  @apply flex items-center w-full;
}

.no-recipients-card {
  @apply max-w-md m-auto mt-12;

  ::v-deep .card.card--has-image-title.card--has-header .card-content {
    @apply mt-0;
  }
}

.margin-spacer {
  height: 1px;
  font-size: 1px;
  color: transparent;
  display: none;
}

@screen sm {
  .margin-spacer {
    display: block;
  }

  .recipients-app-card {
    @apply mt-12;
    @apply mt-0 mb-12;

    @screen sm {
      ::v-deep .card {
        @apply overflow-visible !important;
      }
    }
  }
}
</style>
